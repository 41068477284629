$color_1: #007ced;
$color_2: #ff9800;
$color_3: #f5f5f5;
$color_4: #d5d5d5;
$color_5: #eee;
$color_6: #adadac;
$color_7: #fff;
$color_8: #d3d3d3;
$color_9: #333;
$color_10: #9e9e9e;
$color_11: inherit;
$color_12: #aaa;
$color_13: #0099e5;
$color_14: #212121;
$color_15: #777;
$color_16: #888;
$color_17: #e5e5e5;
$color_18: #bbb;
$color_19: #454545;
$color_20: #353535;
$color_21: #a6a6a6;
$color_22: #e87878;
$color_23: #ade878;
$color_24: #f4511e;
$color_25: #222;
$color_26: #c0392b;
$color_27: #767676;
$color_28: #2d2d2d;
$color_29: #ddd;
$background-color_1: #007ced;
$background-color_2: transparent;
$background-color_3: #f5f5f5;
$background-color_4: #333;
$background-color_5: #d5d5d5;
$background-color_6: #222;
$background-color_7: inherit;
$background-color_8: #fff;
$background-color_9: #252525;
$background-color_10: #fcfcfc;
$background-color_11: #666;
$background-color_12: #353535;
$background-color_13: #444;
$background-color_14: #282828;
$background-color_15: #454545;
$background-color_16: rgba(0, 0, 0, .06);
$background-color_17: #e5e5e5;
$background-color_18: #999;
$background-color_19: rgba(0, 0, 0, .7);
$background-color_20: rgba(255, 255, 255, .55);
$border-color_1: #ff4c4c;
$border-color_2: #d5d5d5;
$border-color_3: #444;
$border-color_4: #007ced;
$border-color_5: #666;
$border-color_6: #aaa;
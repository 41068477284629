@import "./plugins/normalize";
@import "bootstrap/dist/css/bootstrap.css";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@import "./mixins/color";
@import "./mixins/variable";

// @import "https://fonts.googleapis.com/css?family=Poppins:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i";
/* @import 'fonts/linear-icons-font/style.css'; */
/* @import 'fonts/fontawesome-free-5.12.1-web/css/all.min.css'; */
/* @import 'fonts/linecons/style.css'; */
@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1.1);
    opacity: 0;
  }
}
@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0;
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@-webkit-keyframes flipInLeft {
  from {
    -webkit-transform: translateZ(-1000px) rotateY(-90deg);
    opacity: 0.2;
  }
}
@keyframes flipInLeft {
  from {
    -webkit-transform: translateZ(-1000px) rotateY(-90deg);
    transform: translateZ(-1000px) rotateY(-90deg);
    opacity: 0.2;
  }
}
@-webkit-keyframes swap {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scale(0, 0) translate(0px, 0px);
    transform: scale(0, 0) translate(0px, 0px);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scale(1, 1) translate(0px, 0px);
    transform: scale(1, 1) translate(0px, 0px);
  }
}
@keyframes swap {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scale(0, 0) translate(0px, 0px);
    transform: scale(0, 0) translate(0px, 0px);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scale(1, 1) translate(0px, 0px);
    transform: scale(1, 1) translate(0px, 0px);
  }
}
@-webkit-keyframes vanishIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(2, 2);
    transform: scale(2, 2);
    -webkit-filter: blur(90px);
    filter: blur(90px);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
  }
}
@keyframes vanishIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(2, 2);
    transform: scale(2, 2);
    -webkit-filter: blur(90px);
    filter: blur(90px);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
  }
}
@-webkit-keyframes puffIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(2, 2);
    transform: scale(2, 2);
    -webkit-filter: blur(2px);
    filter: blur(2px);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
  }
}
@keyframes puffIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(2, 2);
    transform: scale(2, 2);
    -webkit-filter: blur(2px);
    filter: blur(2px);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
  }
}
@-webkit-keyframes spaceInRight {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(0.2) translate(200%, 0%);
    transform: scale(0.2) translate(200%, 0%);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(1) translate(0%, 0%);
    transform: scale(1) translate(0%, 0%);
  }
}
@keyframes spaceInRight {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(0.2) translate(200%, 0%);
    transform: scale(0.2) translate(200%, 0%);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(1) translate(0%, 0%);
    transform: scale(1) translate(0%, 0%);
  }
}
@-webkit-keyframes twisterInUp {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scale(0, 0) rotate(360deg) translateY(100%);
    transform: scale(0, 0) rotate(360deg) translateY(100%);
  }
  30% {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scale(0, 0) rotate(360deg) translateY(100%);
    transform: scale(0, 0) rotate(360deg) translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(1, 1) rotate(0deg) translateY(0);
    transform: scale(1, 1) rotate(0deg) translateY(0);
  }
}
@keyframes twisterInUp {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scale(0, 0) rotate(360deg) translateY(100%);
    transform: scale(0, 0) rotate(360deg) translateY(100%);
  }
  30% {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scale(0, 0) rotate(360deg) translateY(100%);
    transform: scale(0, 0) rotate(360deg) translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(1, 1) rotate(0deg) translateY(0);
    transform: scale(1, 1) rotate(0deg) translateY(0);
  }
}
@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(16deg);
  }
  20% {
    transform: rotate(-6deg);
  }
  30% {
    transform: rotate(16deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(16deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.hand-wave {
  animation: wave 2.1s 0.6s infinite;
  transform-origin: 75% 75%;
  display: inline-block;
  font-size: 20px;
}
html {
  position: relative;
  height: 100%;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
}
body {
  position: initial;
  font-family: $font-family_1;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: $background-color_1;
  background-position: center center;
  background-size: calc(100% + 25px);
  background-repeat: repeat;
  background-attachment: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: $font-family_1;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.75em;
  color: $color_4;
  > footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    .copyrights {
      display: block;
      margin-bottom: 12px;
      text-align: center;
      font-size: 12px;
      color: $color_10;
    }
  }
}
.lm-animated-bg {
  position: absolute;
  width: auto;
  height: auto;
  top: -18px;
  left: -18px;
  right: -18px;
  bottom: -18px;
  background-image: url(../img/main_bg.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.page-scroll {
  width: 100%;
  height: 100vh;
  min-height: 100%;
  vertical-align: middle;
  -ms-flex-pack: center;
  justify-content: center;
  flex-direction: column;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.add-prespective {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  perspective: 1200px;
}
body.ajax-page-visible {
  overflow: hidden;
}
.transition {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
a {
  color: $color_1;
  text-decoration: none;
  outline: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: $color_2;
    text-decoration: none;
    outline: none;
  }
  &:focus {
    outline: 0;
    text-decoration: none;
  }
}
h1 {
  font-family: $font-family_1;
  font-weight: 600;
  font-style: normal;
  color: $color_3;
  font-size: 32px;
}
h2 {
  font-family: $font-family_1;
  font-weight: 600;
  font-style: normal;
  color: $color_3;
  font-size: 27px;
}
h3 {
  font-family: $font-family_1;
  font-weight: 600;
  font-style: normal;
  color: $color_3;
  font-size: 21px;
}
h4 {
  font-family: $font-family_1;
  font-weight: 600;
  font-style: normal;
  color: $color_3;
  font-size: 18px;
}
h5 {
  font-family: $font-family_1;
  font-weight: 600;
  font-style: normal;
  color: $color_3;
  font-size: 16px;
}
h6 {
  font-family: $font-family_1;
  font-weight: 600;
  font-style: normal;
  color: $color_3;
  font-size: 14px;
}
p {
  font-family: $font-family_1;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.75em;
  color: $color_4;
  font-size: 14px;
  color: $color_4;
  margin: 0 0 10px;
}
p.lead {
  font-size: 18px;
  margin: 25px 0;
}
ol {
  padding: 0 0 0 40px;
  margin: 1em 0;
  ol {
    margin: 1em 0;
  }
  ul {
    margin: 1em 0;
  }
}
ul {
  padding: 0 0 0 40px;
  margin: 1em 0;
  ol {
    margin: 1em 0;
  }
  ul {
    margin: 1em 0;
  }
}
dd {
  margin: 0 0 24px;
}
img {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
  border: 0;
  -ms-interpolation-mode: bicubic;
}
.mobile-visible {
  display: none;
  visibility: hidden;
}
.mobile-hidden {
  display: block;
  visibility: visible;
}
.form-group {
  position: relative;
  margin: 0 0 21.5px;
  .help-block {
    position: absolute;
    display: inline-block;
    padding: 0 5px;
    font-size: 0.93em;
    line-height: 1.75em;
    margin: -2px 0 0 10px;
    color: $color_7;
    background: #ff4e4e;
    z-index: 1;
    &:after {
      content: " ";
      position: absolute;
      left: 5px;
      bottom: 100%;
      width: 0;
      height: 0;
      border-bottom: 10px solid #ff4e4e;
      border-right: 10px solid transparent;
    }
    &:empty {
      display: none;
    }
  }
  .form-control-checkbox + label {
    position: relative;
    display: inline;
    left: 0;
    top: 0;
    font-size: 1em;
    opacity: 1;
  }
  .form-control-checkbox {
    display: inline-block;
    width: auto;
    height: auto;
    top: 2px;
  }
}
.form-control {
  height: 42px;
  position: relative;
  border: 2px solid #555;
  border-radius: 5px;
  display: block;
  font-size: 1em;
  margin: 0;
  padding: 10px 25px 10px 12px;
  width: 100%;
  min-width: 100%;
  background: 0 0;
  text-align: left;
  color: $color_5;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  font-family: $font-family_1;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  &:focus {
    height: 42px;
    position: relative;
    border: 2px solid #555;
    border-radius: 5px;
    display: block;
    font-size: 1em;
    margin: 0;
    padding: 10px 25px 10px 12px;
    width: 100%;
    min-width: 100%;
    background: 0 0;
    text-align: left;
    color: $color_5;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    outline: none;
    font-family: $font-family_1;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
  }
  &:focus + .form-control-border {
    left: 0;
    right: 0;
    height: 100%;
    z-index: 1;
    opacity: 1;
    border-right-width: 2px;
  }
  &::-moz-placeholder {
    color: $color_6;
  }
  &:-ms-input-placeholder {
    color: $color_6;
  }
  &::-webkit-input-placeholder {
    color: $color_6;
  }
}
.has-error {
  .form-control {
    position: relative;
    border: 2px solid #555;
    border-radius: 5px;
    display: block;
    font-size: 1em;
    margin: 0;
    padding: 10px 25px 10px 12px;
    width: 100%;
    min-width: 100%;
    background: 0 0;
    text-align: left;
    color: $color_5;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    outline: none;
    font-family: $font-family_1;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    &:focus {
      position: relative;
      border: 2px solid #555;
      border-radius: 5px;
      display: block;
      font-size: 1em;
      margin: 0;
      padding: 10px 25px 10px 12px;
      width: 100%;
      min-width: 100%;
      background: 0 0;
      text-align: left;
      color: $color_5;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      outline: none;
      font-family: $font-family_1;
      -webkit-transform: translateZ(0);
      -moz-transform: translateZ(0);
      transform: translateZ(0);
    }
  }
  .form-control + .form-control-border {
    border-color: $border-color_1;
    background-color: $background-color_2;
  }
}
input[type="search"] {
  position: relative;
  border: 2px solid #555;
  border-radius: 5px;
  display: block;
  font-size: 1em;
  margin: 0;
  padding: 10px 25px 10px 12px;
  width: 100%;
  min-width: 100%;
  background: 0 0;
  text-align: left;
  color: $color_5;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  font-family: $font-family_1;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
}
input[type="password"] {
  position: relative;
  border: 2px solid #555;
  border-radius: 5px;
  display: block;
  font-size: 1em;
  margin: 0;
  padding: 10px 25px 10px 12px;
  width: 100%;
  min-width: 100%;
  background: 0 0;
  text-align: left;
  color: $color_5;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  font-family: $font-family_1;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
}
input[type="text"] {
  position: relative;
  border: 2px solid #555;
  border-radius: 5px;
  display: block;
  font-size: 1em;
  margin: 0;
  padding: 10px 25px 10px 12px;
  width: 100%;
  min-width: 100%;
  background: 0 0;
  text-align: left;
  color: $color_5;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  font-family: $font-family_1;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
}
.header-search {
  input.form-control {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
  }
}
textarea.form-control {
  height: auto;
  &:focus {
    height: auto;
  }
}
.form-control + .form-control-border {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  border-radius: 5px;
  top: 0;
  opacity: 0;
  background: 0 0 !important;
  border: 2px solid #007ced;
  border-right-width: 0;
  height: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.form-group-with-icon {
  i {
    position: absolute;
    font-size: 16px;
    top: 13px;
    right: 13px;
    color: $color_8;
  }
}
.form-group-with-icon.form-group-focus {
  i {
    color: $color_1;
  }
}
.form-group.form-group-w-checkbox {
  padding-top: 0;
  padding-bottom: 8px;
  margin-bottom: 17px;
  .help-block {
    top: 100%;
    left: 0;
    margin-left: 0;
  }
}
.form-group-with-icon.form-group-w-checkbox {
  padding-top: 8px;
  padding-left: 52px;
  margin-bottom: 25px;
}
.form-group.form-group-focus {
  .form-control-checkbox + label {
    position: relative;
    display: inline;
    left: 0;
    top: 0;
    font-size: 1em;
    opacity: 1;
  }
  .form-control-checkbox {
    display: inline-block;
    width: auto;
    height: auto;
    top: 2px;
  }
}
.form-group.form-group-with-icon.form-group-w-checkbox {
  .help-block {
    left: 52px;
  }
}
.no-padding {
  padding: 0;
}
.subpage-block {
  margin-bottom: 30px;
}
.text-rotation {
  display: block;
}
.center {
  text-align: center;
}
figure {
  max-width: 100%;
}
.hidden {
  display: none;
  visibility: hidden;
}
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  line-height: 1.42857143;
  color: $color_9;
  word-break: break-all;
  word-wrap: break-word;
  background-color: $background-color_3;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.btn-primary {
  display: inline-block;
  position: relative;
  padding: 0.8em 2.1em;
  margin-bottom: 0.75em;
  margin-right: 0.25em;
  font-size: 1em;
  line-height: 1.2;
  border: 0;
  outline: 0;
  border: 2px solid #007ced;
  color: $color_7;
  text-shadow: none;
  background-color: $background-color_4;
  border-radius: 30px;
  font-family: $font-family_1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 10px 10px -8px rgba(0, 0, 0, 0.78);
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    background-color: $background-color_1;
    color: $color_7;
    border: 2px solid #007ced;
  }
  &:focus {
    background-color: $background-color_1;
    color: $color_7;
    border: 2px solid #007ced;
  }
}
.btn-secondary {
  display: inline-block;
  position: relative;
  padding: 0.8em 2.1em;
  margin-bottom: 0.75em;
  margin-right: 0.25em;
  font-size: 1em;
  line-height: 1.2;
  border: 0;
  outline: 0;
  border: 2px solid #007ced;
  color: $color_7;
  text-shadow: none;
  background-color: $background-color_4;
  border-radius: 30px;
  font-family: $font-family_1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 10px 10px -8px rgba(0, 0, 0, 0.78);
  border-color: $border-color_2;
  color: $color_7;
  background-color: $background-color_4;
  box-shadow: 0 10px 10px -8px rgba(0, 0, 0, 0.78);
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: $color_9;
    border-color: $border-color_2;
    background-color: $background-color_5;
  }
  &:focus {
    color: $color_9;
    border-color: $border-color_2;
    background-color: $background-color_5;
  }
}
button {
  display: inline-block;
  position: relative;
  padding: 0.8em 2.1em;
  margin-bottom: 0.75em;
  margin-right: 0.25em;
  font-size: 1em;
  line-height: 1.2;
  border: 0;
  outline: 0;
  border: 2px solid #007ced;
  color: $color_7;
  text-shadow: none;
  background-color: $background-color_4;
  border-radius: 30px;
  font-family: $font-family_1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 10px 10px -8px rgba(0, 0, 0, 0.78);
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    background-color: $background-color_1;
    color: $color_7;
    border: 2px solid #007ced;
  }
  &:focus {
    background-color: $background-color_1;
    color: $color_7;
    border: 2px solid #007ced;
  }
}
input[type="button"] {
  display: inline-block;
  position: relative;
  padding: 0.8em 2.1em;
  margin-bottom: 0.75em;
  margin-right: 0.25em;
  font-size: 1em;
  line-height: 1.2;
  border: 0;
  outline: 0;
  border: 2px solid #007ced;
  color: $color_7;
  text-shadow: none;
  background-color: $background-color_4;
  border-radius: 30px;
  font-family: $font-family_1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 10px 10px -8px rgba(0, 0, 0, 0.78);
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    background-color: $background-color_1;
    color: $color_7;
    border: 2px solid #007ced;
  }
  &:focus {
    background-color: $background-color_1;
    color: $color_7;
    border: 2px solid #007ced;
  }
}
input[type="submit"] {
  display: inline-block;
  position: relative;
  padding: 0.8em 2.1em;
  margin-bottom: 0.75em;
  margin-right: 0.25em;
  font-size: 1em;
  line-height: 1.2;
  border: 0;
  outline: 0;
  // border: 2px solid #007ced;
  color: $color_7;
  text-shadow: none;
  background-color: $background-color_4;
  border-radius: 30px;
  font-family: $font-family_1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 10px 10px -8px rgba(0, 0, 0, 0.78);
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #007ced, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  margin-top: 15px;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    background-color: $background-color_1;
    color: $color_7;
    // border: 2px solid #007ced;
  }
  &:focus {
    background-color: $background-color_1;
    color: $color_7;
    // border: 2px solid #007ced;
  }
}
button#resume-download-btn {
  position: relative;
  padding-right: 3.1em;
  border: none;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #007ced, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    background-color: $background-color_1;
    color: $color_7;
    // border: 2px solid #007ced;
  }
  &:focus {
    background-color: $background-color_1;
    color: $color_7;
    // border: 2px solid #007ced;
  }
  i {
      position: absolute;
      top: 6px;
      font-size: 16px;
  }
  span.resume-download-btn-name {
      padding: 10px 10px 10px;
  }
}
.success-msg {
  display: none;
  p {
    color: #fff;
    font-display: swap;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  &.show-block {
    display: block;
  }
}
.failure-msg {
  display: none;
  p {
    color: #ff0000;
    font-display: swap;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  &.show-block {
    display: block;
  }
}
.wp-block-button {
  .wp-block-button__link {
    display: inline-block;
    position: relative;
    padding: 0.8em 2.1em;
    margin-bottom: 0.75em;
    margin-right: 0.25em;
    font-size: 1em;
    line-height: 1.2;
    border: 0;
    outline: 0;
    border: 2px solid #007ced;
    color: $color_7;
    text-shadow: none;
    background-color: $background-color_4;
    border-radius: 30px;
    font-family: $font-family_1;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 10px 10px -8px rgba(0, 0, 0, 0.78);
    &:last-child {
      margin-right: 0;
    }
  }
}
.mfp-container {
  button {
    box-shadow: none;
  }
}
.border-top {
  border-top: 2px solid #007ced;
}
.border-bottom {
  border-bottom: 2px solid #007ced;
}
.alignleft {
  float: left;
  margin-right: 20px;
}
.alignright {
  float: right;
  margin-left: 20px;
}
.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.alignfull {
  margin-left: -120px;
  margin-right: -120px;
  min-width: calc(100% + 240px);
}
.alignwide {
  margin-left: -60px;
  margin-right: -60px;
  min-width: calc(100% + 120px);
}
.wp-block-image {
  .alignleft {
    margin-right: 30px;
  }
  .alignright {
    margin-left: 30px;
  }
}
blockquote.alignleft {
  margin: 7px 24px 7px 0;
}
figure.wp-caption.alignleft {
  margin: 7px 24px 7px 0;
}
img.alignleft {
  margin: 7px 24px 7px 0;
}
.wp-caption.alignleft {
  margin: 7px 14px 7px 0;
}
.wp-block-cover {
  margin-bottom: 20px;
}
.wp-block-cover-image {
  margin-bottom: 20px;
}
blockquote.alignright {
  margin: 7px 0 7px 24px;
}
figure.wp-caption.alignright {
  margin: 7px 0 7px 24px;
}
img.alignright {
  margin: 7px 0 7px 24px;
}
.wp-caption.alignright {
  margin: 7px 0 7px 14px;
}
blockquote.aligncenter {
  margin-top: 7px;
  margin-bottom: 7px;
}
img.aligncenter {
  margin-top: 7px;
  margin-bottom: 7px;
}
.wp-caption.aligncenter {
  margin-top: 7px;
  margin-bottom: 7px;
}
.site-content {
  blockquote.alignleft {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 17px;
    width: 50%;
    p {
      margin-bottom: 17px;
    }
  }
  blockquote.alignright {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 17px;
    width: 50%;
    p {
      margin-bottom: 17px;
    }
  }
  .entry-header {
    margin-left: 50px;
    margin-right: 50px;
    padding: 5px 0 0;
    .entry-meta {
      font-size: 13px;
    }
  }
  .entry-meta.entry-meta-bottom {
    display: inline-block;
    width: 100%;
    background-color: $background-color_4;
    border: 2px solid #444;
    padding: 0 10px;
    margin: 40px 0 0;
    border-radius: 5px;
  }
  .entry-meta {
    margin-bottom: 12px;
  }
  footer.entry-meta {
    margin: 25px 0;
    padding: 10px;
    font-size: 15px;
    border: 1px solid #eee;
  }
  .entry-summary {
    padding: 6px 0 0;
  }
  .format-link {
    .entry-title {
      display: none;
    }
  }
  .format-aside {
    .entry-title {
      display: none;
    }
  }
  .format-quote {
    .entry-title {
      display: none;
    }
  }
}
table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-collapse: separate;
  border-spacing: 0;
  border-width: 1px 0 0 1px;
  margin-bottom: 24px;
  width: 100%;
}
th {
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 400;
  text-align: left;
  border-width: 0 1px 1px 0;
  font-weight: 700;
  padding: 0.5em;
}
td {
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 400;
  text-align: left;
  border-width: 0 1px 1px 0;
  padding: 0.5em;
}
caption {
  font-weight: 400;
  text-align: left;
}
.p-10 {
  padding-top: 10px;
}
.p-20 {
  padding-top: 20px;
}
.p-30 {
  padding-top: 30px;
}
.p-40 {
  padding-top: 40px;
}
.p-50 {
  padding-top: 50px;
}
.p-60 {
  padding-top: 60px;
}
.p-70 {
  padding-top: 70px;
}
.p-80 {
  padding-top: 80px;
}
.p-90 {
  padding-top: 90px;
}
.p-100 {
  padding-top: 100px;
}
.no-js {
  .preloader {
    display: none;
  }
  .preloader-portfolio {
    display: none;
  }
}
.preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: $background-color_6;
}
.preloader-portfolio {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: $background-color_6;
}
.preloader-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  background-color: $background-color_7;
  color: $color_11;
  opacity: 1;
  transition: opacity 0.3s;
  transform: translate3d(-50%, -50%, 0);
}
.preloader-spinner {
  width: 52px;
  height: 52px;
  margin: 100px auto;
  background-color: $background-color_1;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}
.page-container {
  display: flex;
  flex-flow: column;
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 1240px;
  margin: 3% auto;
  padding: 30px 70px 30px;
  background-color: $background-color_6;
  -webkit-box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.transform3d {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.flex-v-align {
  vertical-align: middle;
  width: 100%;
  min-height: 100%;
  align-items: center;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-pack: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin: 0;
}
.flex-direction-reverse {
  flex-direction: row-reverse;
}
.fw-section-fluid {
  width: calc(100% + 140px);
  margin: 0 -70px;
  padding: 0 45px;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}
.transition-flip-in-right {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: flipInLeft 0.5s both ease-out;
  animation: flipInLeft 0.5s both ease-out;
}
.transition-swap {
  -webkit-animation-name: swap;
  animation-name: swap;
}
.transition-vanish-in {
  -webkit-animation-name: vanishIn;
  animation-name: vanishIn;
}
.transition-puff-in {
  -webkit-animation-name: puffIn;
  animation-name: puffIn;
}
.transition-space-in-right {
  -webkit-animation-name: spaceInRight;
  animation-name: spaceInRight;
}
.transition-twister-in-up {
  -webkit-animation-name: twisterInUp;
  animation-name: twisterInUp;
}
.header {
  display: block;
  width: 100%;
  padding-bottom: 40px;
  position: relative;
}
.header.fixed {
  position: fixed;
  left: 0;
  top: 0;
  background-color: $background-color_8;
  right: 0;
  margin: 0 auto;
  max-width: 1240px;
  padding: 20px 50px;
}
.text-logo {
  float: left;
  a {
    display: block;
    outline: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    &:focus {
      outline: 0;
    }
  }
}
.logo-symbol {
  float: left;
  width: 44px;
  height: 44px;
  padding: 13px;
  background-color: #007ced;
  border-radius: 100px;
  text-align: center;
  color: #fff;
  font-size: 28px;
  font-weight: 800;
  margin-right: 8px;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #007ced, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}
.logo-text {
  float: left;
  line-height: 50px;
  font-size: 20px;
  color: $color_7;
  font-weight: 600;
  span {
    font-weight: 400;
  }
}
.header-image {
  float: left;
  margin-right: 10px;
}
.site-nav {
  float: right;
}
.site-main-menu {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 99;
  li {
    display: inline-block;
    position: relative;
    color: $color_9;
    text-decoration: none;
    margin-right: 40px;
    &:last-child {
      margin-right: 0;
    }
    a {
      color: $color_3;
      text-decoration: none;
      line-height: 50px;
      font-size: 14px;
      opacity: 0.55;
      &.active {
        opacity: 1;
      }
    }
    &:hover {
      > a {
        opacity: 1;
      }
    }
  }
  li.current-menu-item {
    > a {
      opacity: 1;
    }
  }
  li.current-menu-parent {
    > a {
      opacity: 1;
    }
  }
}
.page-title {
  display: block;
  position: relative;
  background-color: $background-color_9;
  border-top: 2px solid #333;
  border-bottom: 2px solid #333;
  padding: 65px 70px;
  margin-right: -70px;
  margin-left: -70px;
  margin-bottom: 65px;
  text-align: left;
  h1 {
    font-size: 44px;
    font-weight: 600;
    margin: 0;
  }
  .page-subtitle {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 70px;
    h4 {
      color: $color_12;
      font-weight: 400;
      font-size: 14px;
      margin: 1.33em 0;
    }
  }
}
.list-view {
  .page-title {
    margin-bottom: 35px;
  }
  .paging-navigation {
    margin-left: 30px;
    margin-right: 30px;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50px;
    margin-right: 50px;
  }
  .single-page-content {
    .content-area {
      &:not(.projects-grid) {
        .page-content {
          padding: 0 0 20px;
        }
      }
    }
  }
  .post-content {
    margin-left: 50px;
    margin-right: 50px;
  }
  .post-navigation {
    margin-left: 50px;
    margin-right: 50px;
  }
  .comments-area {
    margin-left: 50px;
    margin-right: 50px;
  }
  .site-content {
    article {
      position: relative;
      padding-bottom: 40px;
      margin-bottom: 40px;
      border-bottom: 1px solid #eee;
      &:last-child {
        padding-bottom: 0;
        margin-bottom: 20px;
        border-bottom: 0;
      }
    }
  }
}
.page-layout {
  position: relative;
  min-height: 100%;
  height: 100%;
}
.page-header {
  margin-top: 0;
}
.content-page-with-sidebar {
  .page-content {
    width: 100%;
  }
}
.blog-sidebar {
  position: fixed;
  display: block;
  width: 310px;
  right: -310px;
  background-color: $background-color_10;
  padding: 0;
  top: 0;
  height: 100%;
  float: none;
  z-index: 101;
  opacity: 1;
  visibility: visible;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.66s ease-in-out;
  -moz-transition: all 0.66s ease-in-out;
  -o-transition: all 0.66s ease-in-out;
  -ms-transition: all 0.66s ease-in-out;
  transition: all 0.66s ease-in-out;
  .blog-sidebar-content {
    overflow: auto;
    height: 100%;
    padding: 15px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.66s ease-in-out;
    -moz-transition: all 0.66s ease-in-out;
    -o-transition: all 0.66s ease-in-out;
    -ms-transition: all 0.66s ease-in-out;
    transition: all 0.66s ease-in-out;
  }
  .sidebar-item {
    margin-bottom: 10px;
    font-size: 0.9em;
    border: 2px solid #eee;
    background-color: $background-color_8;
    border-radius: 5px;
    &:first-child {
      .searchform {
        margin-top: 0;
      }
    }
  }
  li {
    font-size: 1em;
  }
  select {
    width: auto;
    max-width: 100%;
  }
  p {
    font-size: 1em;
  }
  ul {
    display: inline-block;
    max-width: 100%;
    padding: 10px 20px 15px;
    margin: 4px 0;
    list-style: none;
    ul {
      padding: 5px;
    }
    li {
      padding-left: 15px;
      margin: 5px 0;
      position: relative;
      color: $color_12;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        border: 2px solid #007ced;
        height: 7px;
        width: 7px;
        border-radius: 8px;
        display: inline-block;
        top: 7px;
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }
  .textwidget {
    display: inline-block;
    max-width: 100%;
    padding: 10px 20px 15px;
    margin: 4px 0;
    list-style: none;
  }
  .tagcloud {
    display: inline-block;
    max-width: 100%;
    padding: 10px 20px 15px;
    margin: 4px 0;
    list-style: none;
  }
  .sub-menu {
    width: 100%;
    ul {
      margin-top: 0;
      margin-bottom: 0;
    }
    li {
      padding: 0 0 0 7px;
      margin: 0;
      &:before {
        display: none;
      }
    }
  }
  .children {
    width: 100%;
  }
  a {
    color: $color_16;
    &:hover {
      color: $color_11;
    }
  }
  .searchform {
    margin: 4px 0;
  }
  h6 {
    display: block;
    position: relative;
    padding: 5px 22px 0;
    margin: 15px 0 0;
    color: $color_7;
    .demo-icons {
      color: $color_7;
      margin-right: 0.6em;
    }
  }
  .sidebar-title {
    display: block;
    position: relative;
    padding: 5px 22px 0;
    margin: 15px 0 0;
    color: $color_7;
    h4 {
      display: inline-block;
      font-size: 17px;
      position: relative;
      margin: 0;
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 30px;
        height: 2px;
        left: 0;
        bottom: -4px;
        background-color: $background-color_1;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        bottom: -4px;
        background-color: $background-color_3;
      }
    }
    .demo-icons {
      color: $color_7;
      margin-right: 0.6em;
    }
    .fa {
      color: $color_7;
      margin-right: 0.6em;
    }
    .rsswidget {
      img {
        vertical-align: baseline;
      }
    }
  }
  .post-date {
    font-size: 11px;
  }
  .separator {
    display: none;
  }
  .search-form {
    input[type="search"] {
      border: none;
      background-color: $background-color_2;
    }
    input[type="text"] {
      border: none;
      background-color: $background-color_2;
    }
  }
}
.hidden-sidebar {
  opacity: 0;
  visibility: hidden;
}
.blog-sidebar.open {
  .blog-sidebar-content {
    opacity: 1;
    visibility: visible;
  }
  right: 0;
  -webkit-box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.08);
}
.sidebar-toggle {
  position: absolute;
  display: block;
  width: 48px;
  height: 48px;
  line-height: 46px;
  text-align: center;
  background-color: $background-color_10;
  left: -48px;
  font-size: 19px;
  top: 83px;
  border: 2px solid #eee;
  border-right-width: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 40%;
    background: #666;
    border-radius: 5px;
    opacity: 1;
    left: 17px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    &:before {
      content: "";
      width: 3px;
      height: 3px;
      background-color: $background-color_11;
      position: absolute;
      left: -4px;
      border-radius: 5px;
    }
    &:nth-child(1) {
      top: 14px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    &:nth-child(2) {
      top: 20px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    &:nth-child(3) {
      top: 26px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
  }
}
.sidebar-toggle.open {
  span {
    &:before {
      left: -2px;
    }
    &:nth-child(1) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      top: 15px;
    }
    &:nth-child(2) {
      width: 0%;
      opacity: 0;
    }
    &:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 26px;
    }
  }
}
.block-title {
  h2 {
    display: inline-block;
    position: relative;
    font-size: 21px;
    margin: 0 0 30px;
    z-index: 1;
    padding-bottom: 7px;
    &:after {
      display: block;
      position: absolute;
      content: "";
      width: 30px;
      // background-color: $background-color_1;
      height: 2px;
      bottom: 0;
      background: linear-gradient(-45deg, #ee7752, #e73c7e, #007ced, #23d5ab);
      background-size: 400% 400%;
      animation: gradient 15s ease infinite;
    }
    &:before {
      display: block;
      position: absolute;
      content: "";
      width: 100%;
      background-color: $background-color_12;
      height: 2px;
      bottom: 0;
    }
  }
  h3 {
    span {
      color: $color_1;
    }
  }
}
h3.comment-reply-title {
  display: inline-block;
  position: relative;
  font-size: 21px;
  margin: 0 0 30px;
  z-index: 1;
  padding-bottom: 7px;
  &:after {
    display: block;
    position: absolute;
    content: "";
    width: 30px;
    background-color: $background-color_1;
    height: 2px;
    bottom: 0;
  }
  &:before {
    display: block;
    position: absolute;
    content: "";
    width: 100%;
    background-color: $background-color_12;
    height: 2px;
    bottom: 0;
  }
}
.custom-page-content {
  .block {
    margin-bottom: 30px;
  }
  .page-content {
    background-color: $background-color_8;
  }
}
.lmpixels-scroll-to-top {
  position: fixed;
  bottom: 10px;
  right: 15px;
  z-index: 100;
  display: inline-block;
  padding: 0;
  background-color: $background-color_4;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 24px;
  cursor: pointer;
  border: 1px solid #222;
  color: $color_12;
  opacity: 1;
  border-radius: 8px;
  visibility: visible;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);
  &:hover {
    color: $color_13;
  }
}
.lmpixels-scroll-to-top.hidden-btn {
  opacity: 0;
  visibility: hidden;
}
.home-content {
  margin: 3% 10%;
}
.home-text {
  text-align: left;
  padding-right: 10px;
  h1 {
    font-size: 48px;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 0;
  }
  h4 {
    font-size: 16px;
    color: $color_12;
    font-weight: 300;
    margin-bottom: 10px;
  }
  span {
    &.txt-rotate {
      margin-bottom: 20px;
      display: block;
    }
  }
}
.home-text.hp-left {
  padding-left: 25px;
  padding-right: 0;
}
.home-photo {
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: calc(100% - 36px);
  border-radius: 1000px;
  overflow: hidden;
  background-color: $background-color_13;
  border: 18px solid #444;
  -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.8);
  .hp-inner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    display: block;
    position: absolute;
    top: -18px;
    bottom: -18px;
    width: auto;
    left: -18px;
    right: -18px;
  }
}
.home-buttons {
  margin-top: 27px;
}
.info-list {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin: 6px 0;
    text-align: left;
    .title {
      display: inline-block;
      font-weight: 600;
      margin-right: 15px;
      font-family: $font-family_3;
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: $background-color_1;
      }
    }
    .value {
      display: inline-block;
    }
  }
}
.social-links-block {
  margin: 25px 0 5px;
}
ul.social-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  li {
    display: inline-block;
    margin: 0;
    padding: 0;
    a {
      display: inline-block;
      margin-right: 5px;
      width: 40px;
      height: 40px;
      font-size: 20px;
      padding: 7px;
      color: $color_7;
      text-align: center;
      vertical-align: middle;
      align-items: center;
      // background: $color_7;
      background: linear-gradient(-45deg, #ee7752, #e73c7e, #007ced, #23d5ab);
      background-size: 200% 100%;
      animation: gradient 15s ease infinite;
      // border: 1px solid $color_7;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      overflow: hidden;
      position: relative;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: $color_14;
        .front-icon {
          opacity: 0;
        }
        .back-icon {
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
      i {
        font-size: 16px;
        line-height: 32px;
      }
      img {
        vertical-align: text-bottom;
        line-height: 100%;
      }
      svg {
        vertical-align: text-top;
      }
      .front-icon {
        align-items: center;
        display: flex;
        justify-content: center;
        transition: all 0.5s ease-in-out;
      }
      .back-icon {
        align-items: center;
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        -webkit-transform: scale(3);
        transform: scale(3);
        transition: opacity 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out,
          -webkit-transform 0.5s ease-in-out;
        svg {
          color: #2b2b2b;
        }
      }
    }
    &:last-child {
      a {
        margin-right: 0;
      }
    }
  }
}
.single-page-content.start-page-template {
  .content-area {
    position: initial;
  }
}
.info-block-w-icon {
  position: relative;
  text-align: left;
  width: 100%;
  display: table;
  margin: 0;
  padding: 0 10px 30px 0;
  .ci-icon {
    position: relative;
    display: table-cell;
    padding: 0 10px 5px 0;
    width: 44px;
    font-size: 25px;
  }
  .ci-text {
    position: relative;
    display: table-cell;
    padding: 0 0 0 15px;
    vertical-align: middle;
    h4 {
      margin: 7px 0;
    }
    p {
      font-size: 0.92em;
      &:last-child {
        margin-bottom: 0;
      }
    }
    a{
      color: #f5f5f5;
    }
  }
  i {
    position: relative;
    display: table-cell;
    font-size: 33px;
    color: $color_1;
  }
  img {
    position: relative;
    max-width: 54px;
  }
}
.timeline-item {
  position: relative;
  padding: 25px 0 20px 40px;
  &:before {
    content: "";
    position: absolute;
    display: block;
    height: 100%;
    background-color: $background-color_4;
    width: 1px;
    left: 15px;
    bottom: 5px;
  }
  &:after {
    content: "";
    position: absolute;
    display: block;
    height: 1px;
    background-color: $background-color_4;
    width: calc(100% - 15px);
    left: 15px;
    bottom: 3px;
  }
  &:first-child {
    padding-top: 3px;
  }
  &:last-child {
    &:after {
      display: none;
    }
    margin-bottom: 30px;
  }
  h5.item-period {
    display: inline-block;
    position: relative;
    font-size: 14px;
    font-weight: bolder;
    margin: 0 0 5px -40px;
    color: $color_4;
    background-color: $background-color_6;
    padding: 5px 15px;
    line-height: normal;
    border: 2px solid #000;
    border-radius: 30px;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #007ced, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
  }
  .item-company {
    display: inline-block;
    font-size: 14px;
    color: $color_12;
    opacity: 0.7;
    margin-left: 5px;
  }
  .item-title {
    font-size: 18px;
    margin: 10px 0 7px;
  }
  p {
    font-size: 1em;
  }
}
.certificate-item {
  position: relative;
  display: table;
  table-layout: fixed;
  width: 100%;
  border: 2px solid #444;
  border-radius: 8px;
  margin-bottom: 20px;
  -webkit-transition: all 2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  &:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-box-shadow: 0 12px 17px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 12px 17px rgba(0, 0, 0, 0.12);
    -o-box-shadow: 0 12px 17px rgba(0, 0, 0, 0.12);
    box-shadow: 0 12px 17px rgba(0, 0, 0, 0.12);
  }
}
.certi-logo {
  display: table-cell;
  width: 120px;
  height: 100%;
  background-color: $background-color_13;
  padding: 25px;
  vertical-align: middle;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  img {
    display: block;
    max-width: 100%;
  }
}
.certi-content {
  display: table-cell;
  width: 100%;
  padding: 20px 25px;
  vertical-align: middle;
}
.certi-title {
  h4 {
    font-size: 16px;
    margin: 0 0 5px;
  }
}
.certi-id {
  font-size: 0.9em;
  color: $color_15;
}
.certi-date {
  font-size: 0.7em;
  color: $color_15;
  opacity: 0.7;
}
.skills-info.skills-first-style {
  margin-bottom: 30px;
  h4 {
    font-size: 13px;
    line-height: 1.1em;
    position: relative;
    float: left;
    margin: 0 0 4px;
  }
}
.skills-first-style {
  .skill-container {
    position: relative;
    background-color: $background-color_2;
    border: 2px solid #444;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 12px;
    margin-bottom: 15px;
    width: 100%;
  }
  .skill-value {
    font-size: 11px;
    line-height: 1.1em;
    position: relative;
    float: right;
    margin: 0 0 4px;
    color: $color_16;
  }
  .skill-percentage {
    // background-color: $background-color_1;
    border: 2px solid #222;
    -moz-border-radius: 9px;
    -webkit-border-radius: 9px;
    border-radius: 9px;
    height: 8px;
    padding: 0;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #007ced, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
  }
}
.service-block {
  text-align: center;
  padding: 0;
  margin-bottom: 30px;
  img {
    max-width: 100px;
    max-height: 74px;
    margin: 0 0 18px;
  }
}
.service-icon {
  display: block;
  font-size: 48px;
  line-height: 80px;
  color: $color_7;
  background-color: $background-color_1;
  width: 80px;
  height: 80px;
  border-radius: 100px;
  margin: 5px auto 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &:hover {
    -webkit-transform: translateY(-7px);
    -ms-transform: translateY(-7px);
    -o-transform: translateY(-7px);
    transform: translateY(-7px);
    -webkit-box-shadow: 0 7px 12px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 7px 12px rgba(0, 0, 0, 0.15);
    -o-box-shadow: 0 7px 12px rgba(0, 0, 0, 0.15);
    box-shadow: 0 7px 12px rgba(0, 0, 0, 0.15);
  }
}
.testimonial-item {
  padding: 30px;
  margin-bottom: 10px;
}
.testimonial-content {
  position: relative;
  padding: 30px 20px 20px 60px;
  background-color: $background-color_14;
  border-radius: 8px;
  border: 2px solid #444;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.testimonial-picture {
  position: absolute;
  display: block;
  top: -30px;
  left: -30px;
  width: 80px;
  height: 80px;
  img {
    display: inline-block;
    border-radius: 80px;
    border-color: $border-color_3;
  }
}
.testimonial-text {
  p {
    font-style: italic;
  }
}
.testimonial-author-info {
  margin-top: 15px;
}
.testimonial-author {
  margin: 0 0 2px;
  font-size: 0.9em;
  color: $color_17;
}
.testimonial-firm {
  margin: 0;
  font-size: 0.8em;
  color: $color_18;
}
.testimonial-icon {
  display: block;
  line-height: 20px;
  font-size: 20px;
  position: absolute;
  left: 20px;
  bottom: 46px;
  color: $color_19;
}
.testimonial-icon-big {
  display: block;
  line-height: 44px;
  font-size: 44px;
  position: absolute;
  right: -24px;
  bottom: -24px;
  color: $color_20;
  z-index: -1;
}
.client-block {
  text-align: center;
  padding: 5px 0;
  margin-bottom: 30px;
  opacity: 0.7;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  &:hover {
    opacity: 1;
  }
  img {
    max-width: 80px;
    margin: 0 auto;
  }
}
.clients.owl-carousel {
  .client-block {
    padding: 10px 15px 15px;
    margin-bottom: 15px;
  }
  .owl-item {
    img {
      display: block;
      width: 100%;
      max-width: 100px;
    }
  }
  .owl-dots {
    position: relative;
    width: 100%;
    text-align: center;
  }
  .owl-dot {
    display: inline-block;
    margin: 3px 0;
    span {
      display: block;
      height: 3px;
      background-color: $background-color_15;
      border-radius: 3px;
      margin: 4px;
      width: 20px;
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
      -o-transition: all 0.25s ease-in-out;
      -ms-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
    }
    &:hover {
      span {
        background-color: $background-color_1;
      }
    }
  }
  .owl-dot.active {
    span {
      background-color: $background-color_1;
      background-color: $background-color_1;
      width: 30px;
    }
  }
  .owl-nav {
    position: absolute;
    text-align: right;
    right: 0;
    bottom: 5px;
    .owl-prev {
      margin-right: 3px;
      display: inline-block;
      font-family: $font-family_2;
      font-weight: 900;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      background-color: $background-color_8;
      border: 1px solid #e5e5e5;
      -webkit-transition-duration: 0.2s;
      transition-duration: 0.2s;
      &:hover {
        color: $color_7;
        background-color: $background-color_1;
        border-color: $border-color_4;
      }
      &:before {
        position: relative;
        margin: 2px;
        width: 22px;
        height: 22px;
        font-size: 11px;
        line-height: 22px;
        text-align: center;
        display: block;
        cursor: pointer;
        content: "\f053";
      }
    }
    .owl-next {
      display: inline-block;
      font-family: $font-family_2;
      font-weight: 900;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      background-color: $background-color_8;
      border: 1px solid #e5e5e5;
      -webkit-transition-duration: 0.2s;
      transition-duration: 0.2s;
      &:hover {
        color: $color_7;
        background-color: $background-color_1;
        border-color: $border-color_4;
      }
      &:before {
        position: relative;
        margin: 2px;
        width: 22px;
        height: 22px;
        font-size: 11px;
        line-height: 22px;
        text-align: center;
        display: block;
        cursor: pointer;
        content: "\f054";
      }
    }
  }
}
.testimonials.owl-carousel {
  .owl-dots {
    position: relative;
    width: 100%;
    text-align: center;
  }
  .owl-dot {
    display: inline-block;
    margin: 3px 0;
    span {
      display: block;
      height: 3px;
      background-color: $background-color_15;
      border-radius: 3px;
      margin: 4px;
      width: 20px;
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
      -o-transition: all 0.25s ease-in-out;
      -ms-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
    }
    &:hover {
      span {
        background-color: $background-color_1;
      }
    }
  }
  .owl-dot.active {
    span {
      background-color: $background-color_1;
      background-color: $background-color_1;
      width: 30px;
    }
  }
  .owl-nav {
    position: absolute;
    text-align: right;
    right: 0;
    bottom: 5px;
    .owl-prev {
      margin-right: 3px;
      display: inline-block;
      font-family: $font-family_2;
      font-weight: 900;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      background-color: $background-color_8;
      border: 1px solid #e5e5e5;
      -webkit-transition-duration: 0.2s;
      transition-duration: 0.2s;
      &:hover {
        color: $color_7;
        background-color: $background-color_1;
        border-color: $border-color_4;
      }
      &:before {
        position: relative;
        margin: 2px;
        width: 22px;
        height: 22px;
        font-size: 11px;
        line-height: 22px;
        text-align: center;
        display: block;
        cursor: pointer;
        content: "\f053";
      }
    }
    .owl-next {
      display: inline-block;
      font-family: $font-family_2;
      font-weight: 900;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      background-color: $background-color_8;
      border: 1px solid #e5e5e5;
      -webkit-transition-duration: 0.2s;
      transition-duration: 0.2s;
      &:hover {
        color: $color_7;
        background-color: $background-color_1;
        border-color: $border-color_4;
      }
      &:before {
        position: relative;
        margin: 2px;
        width: 22px;
        height: 22px;
        font-size: 11px;
        line-height: 22px;
        text-align: center;
        display: block;
        cursor: pointer;
        content: "\f054";
      }
    }
  }
}
.lm-info-block {
  position: relative;
  text-align: center;
  width: 100%;
  display: block;
  margin: 0 0 15px;
  background-color: $background-color_4;
  padding: 25px 10px 15px;
  border: 2px solid #444;
  border-radius: 10px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  overflow: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  &:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-box-shadow: 0 12px 17px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 12px 17px rgba(0, 0, 0, 0.12);
    -o-box-shadow: 0 12px 17px rgba(0, 0, 0, 0.12);
    box-shadow: 0 12px 17px rgba(0, 0, 0, 0.12);
  }
  h4 {
    font-size: 16px;
    margin: 15px;
  }
  i {
    position: relative;
    font-size: 33px;
    color: $color_1;
    margin-bottom: 6px;
    width: 33px;
    height: 33px;
  }
  .lm-info-block {
    font-size: 24px;
    font-weight: 400;
    display: block;
    margin: 10px 0;
    color: $color_1;
  }
}
.lm-info-block.gray-bg {
  background-color: $background-color_10;
}
.lm-info-block-value {
  color: $color_4;
  font-size: 48px;
  line-height: 52px;
  display: block;
  margin: 15px 0 10px;
}
.lm-info-block-text {
  display: block;
}
.portfolio-filters {
  list-style: none;
  padding: 0;
  margin: 0 0 2em;
  text-align: right;
  li {
    display: inline-block;
    a {
      color: $color_3;
      font-size: 12px;
      line-height: 1.5;
      padding: 3px 14px;
      text-decoration: none;
      opacity: 0.5;
      cursor: pointer;
      &:hover {
        color: $color_3;
        font-size: 12px;
        line-height: 1.5;
        padding: 3px 14px;
        text-decoration: none;
        opacity: 0.5;
        cursor: pointer;
        opacity: 1;
      }
      &:not([href]) {
        color: $color_3;
        font-size: 12px;
        line-height: 1.5;
        padding: 3px 14px;
        text-decoration: none;
        opacity: 0.5;
        cursor: pointer;
      }
    }
  }
  li.active {
    a {
      opacity: 1;
      &:hover {
        opacity: 1;
      }
    }
  }
}
.pf-load-more {
  margin-top: 40px;
}
.portfolio-grid {
  margin-left: -8px;
  margin-right: -8px;
  figure {
    width: 33.33333%;
    float: left;
    position: relative;
    overflow: hidden;
    margin: 8px;
    border-radius: 12px;
    a {
      display: block;
      position: relative;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 3;
    }
    img {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      img {
        transform: scale(1.2);
      }
      .portfolio-item-img {
        &:after {
          opacity: 1;
        }
      }
      i {
        opacity: 1;
      }
      .category {
        opacity: 1;
      }
      .mask-gallery-grid {
        visibility: visible;
        opacity: 1;
      }
    }
    .portfolio-item-img {
      &:after {
        content: "";
        position: absolute;
        background-color: $background-color_16;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }
    }
    i {
      display: inline-block;
      position: absolute;
      bottom: 20px;
      right: 20px;
      font-size: 16px;
      width: 30px;
      height: 30px;
      background-color: $background-color_6;
      color: $color_1;
      text-align: center;
      line-height: 25px;
      border-radius: 5px;
      opacity: 0;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
    .name {
      display: inline-block;
      padding: 4px 10px;
      margin: 0;
      background-color: $background-color_6;
      color: $color_3;
      font-size: 14px;
      line-height: 1.2;
      top: 20px;
      left: 20px;
      max-width: calc(100% - 67px);
      border-radius: 5px;
      position: absolute;
    }
    .category {
      display: inline-block;
      padding: 3px 7px;
      background-color: $background-color_6;
      color: $color_3;
      font-size: 11px;
      line-height: 1.2em;
      bottom: 20px;
      left: 20px;
      position: absolute;
      border-radius: 5px;
      opacity: 0;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
  }
  img {
    position: relative;
    display: block;
    width: 100%;
    position: relative;
    display: block;
    width: 100%;
  }
}
.portfolio-grid.one-column {
  figure {
    width: 100%;
  }
}
.portfolio-grid.two-columns {
  figure {
    width: calc(50% - 16px);
  }
}
.portfolio-grid.three-columns {
  figure {
    width: calc(33.33333% - 16px);
  }
}
.portfolio-grid.four-columns {
  figure {
    width: calc(25% - 16px);
  }
}
.portfolio-grid.five-columns {
  figure {
    width: calc(20% - 16px);
    i {
      bottom: 10px;
      right: 10px;
    }
    .name {
      font-size: 13px;
      top: 10px;
      left: 10px;
    }
    .category {
      bottom: 10px;
      left: 10px;
    }
  }
}
.portfolio-rid {
  figure {
    img {
      display: block;
      position: relative;
    }
  }
}
.portfolio-item-img {
  position: relative;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  border-radius: 8px;
}
.portfolio-item-desc {
  margin-top: 12px;
  h4 {
    margin-bottom: 0;
  }
  small {
    color: $color_16;
  }
}
.load-more-btn-container {
  text-align: center;
}
.blog-masonry {
  margin: 0 -1em 0.5em;
}
.blog-masonry.one-column {
  .item {
    width: 100%;
    padding: 0 1em 2em;
  }
}
.blog-masonry.two-columns {
  .item {
    width: 50%;
    float: left;
    padding: 0 1em 2em;
  }
}
.blog-masonry.three-columns {
  .item {
    width: 33.333333%;
    float: left;
    padding: 0 1em 2em;
  }
}
.blog-card {
  display: block;
  position: relative;
  padding: 0;
  .post-image {
    width: 100%;
    margin: 0;
  }
  .media-block {
    overflow: hidden;
    border-bottom: 0;
    border-radius: 10px;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    position: relative;
    img {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
      a {
        .mask {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    a {
      .mask {
        position: absolute;
        background: #4f565e;
        background: rgba(0, 0, 0, 0.12);
        display: inline-block;
        font-family: $font-family_4;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        width: 100%;
        left: 0;
        bottom: 0;
        top: 0;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        &:after {
          position: absolute;
          left: 50%;
          top: 50%;
          margin: -22px 0 0 -22px;
          width: 44px;
          line-height: 44px;
          font-size: 44px;
          text-align: center;
          content: "\e828";
          color: $color_7;
        }
      }
    }
  }
  .post-without-f-image {
    padding-bottom: 46.875%;
    width: 100%;
    background-color: $background-color_3;
  }
  .post-info {
    padding: 10px 0 15px;
  }
  .blog-item-title {
    margin: 5px 0 0;
  }
  .post-meta {
    padding: 1em;
    margin: 0;
    .item {
      color: $color_21;
    }
  }
  .category {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 3;
    a {
      position: relative;
      background: #222;
      border-radius: 3px;
      padding: 3px 7px;
      line-height: 1.2em;
      font-size: 11px;
      color: $color_3;
    }
  }
  .post-date {
    display: inline-block;
    color: $color_12;
    font-size: 11px;
    line-height: 1.1em;
    font-weight: 400;
  }
  ul.category {
    li {
      a {
        color: $color_16;
        font-size: 12px;
        font-weight: 300;
      }
    }
  }
}
.sticky-badge {
  position: absolute;
  background-color: $background-color_8;
  right: 10px;
  top: 10px;
  font-size: 12px;
  padding: 0;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  color: $color_16;
  z-index: 3;
  border-radius: 3px;
}
.post-meta {
  .item {
    a {
      color: $color_21;
    }
    display: inline-block;
    font-size: 13px;
    margin-right: 10px;
    &:before {
      padding-right: 5px;
    }
  }
}
.g-recaptcha {
  margin-bottom: 20px;
}
.map {
  width: 100%;
  height: 150px;
  margin: 0 0 50px;
}
.controls.two-columns {
  .left-column {
    width: 47%;
    float: left;
    margin-right: 3%;
  }
  .right-column {
    width: 50%;
    float: right;
  }
}
.testimonials {
  width: 100%;
}
.portfolio-page-carousel {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  .owl-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    margin-top: -22px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    [class*="owl-"] {
      color: $color_10;
      margin: 0;
      padding: 0;
      width: 44px;
      height: 44px;
      background: #fff;
      display: inline-block;
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      &:before {
        display: block;
        font-family: $font-family_4;
        font-size: 30px;
        line-height: 44px;
      }
    }
    .owl-prev {
      position: absolute;
      margin-left: -50px;
      left: 0;
      &:before {
        content: "\e875";
      }
    }
    .owl-next {
      position: absolute;
      margin-right: -50px;
      right: 0;
      &:before {
        content: "\e876";
      }
    }
  }
  &:hover {
    .owl-nav {
      .owl-prev {
        margin-left: 0;
      }
      .owl-next {
        margin-right: 0;
      }
    }
  }
  .owl-dots {
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0 10px 5px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    .owl-dot {
      display: inline-block;
      &:hover {
        span {
          background: #9e9e9e;
        }
      }
      span {
        width: 12px;
        height: 12px;
        margin: 5px;
        background: #f5f5f5;
        border: 2px solid #9e9e9e;
        display: block;
        -webkit-backface-visibility: visible;
        transition: opacity 200ms ease;
        border-radius: 30px;
      }
    }
    .owl-dot.active {
      span {
        background: #9e9e9e;
      }
    }
  }
}
.page-ajax-loaded {
  &:empty {
    display: none !important;
  }
}
.page-portfolio-loaded {
  position: fixed;
  background-color: $background-color_6;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1003;
  overflow: hidden;
  .preloader {
    background-color: $background-color_8;
  }
  > article {
    height: 100%;
    > div {
      height: 100%;
    }
    .entry-content {
      padding-bottom: 0;
    }
  }
  .ajax-page-content {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .ajax-page-wrapper {
    max-width: 1170px;
    margin: 0 auto;
    padding: 20px 60px 50px;
  }
}
.ajax-page-nav {
  text-align: right;
  float: right;
  margin-bottom: 10px;
  > div.nav-item {
    position: relative;
    display: inline-block;
    margin: 0 3px;
    a {
      display: inline-block;
      height: 40px;
      width: 40px;
      text-align: center;
      color: $color_12;
      background-color: $background-color_4;
      border-radius: 5px;
      &:hover {
        background-color: $background-color_1;
        color: $color_7;
      }
      i {
        line-height: 40px;
        font-size: 22px;
      }
    }
  }
}
.ajax-page-title {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: left;
  h1 {
    display: inline-block;
    margin: 0;
  }
}
.portfolio-page-page-content {
  img {
    max-width: 100%;
  }
}
.ajax-page-content {
  .embed-video {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .single-image {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .share-buttons {
    a {
      margin: 0 7px 0 0;
    }
  }
}
.portfolio-page-video {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}
.portfolio-page-image {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  .embed-responsive-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  embed {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  object {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  &::before {
    display: block;
    content: "";
  }
}
.embed-responsive-21by9 {
  &:before {
    padding-top: 42.857143%;
  }
}
.embed-responsive-16by9 {
  &:before {
    padding-top: 56.25%;
  }
}
.embed-responsive-4by3 {
  &:before {
    padding-top: 75%;
  }
}
.embed-responsive-1by1 {
  &:before {
    padding-top: 100%;
  }
}
.ajax-page-wrapper {
  .portfolio-grid {
    margin-bottom: 25px;
  }
  .block-title {
    h3 {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }
}
.project-description {
  display: block;
  position: relative;
  padding: 25px 30px;
  background-color: $background-color_4;
  border-radius: 15px;
  border: 2px solid #444;
  margin-left: 20px;
}
.project-general-info {
  list-style: none;
  padding: 0;
  margin: 0 0 15px;
  list-style: none;
  padding: 0;
  margin: 0 0 15px;
  li {
    margin: 0 0 10px;
    margin: 0 0 10px;
  }
  .fa {
    color: $color_1;
    margin-right: 5px;
    color: $color_1;
    margin-right: 5px;
  }
  p {
    margin: 0;
  }
}
.entry-content {
  .ajax-page-content {
    .project-general-info {
      p {
        margin: 0;
        font-size: 1em;
      }
    }
  }
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
  h1 {
    &:first-child {
      margin-top: 0;
    }
  }
  h2 {
    &:first-child {
      margin-top: 0;
    }
  }
  h3 {
    &:first-child {
      margin-top: 0;
    }
  }
  h4 {
    &:first-child {
      margin-top: 0;
    }
  }
  h5 {
    &:first-child {
      margin-top: 0;
    }
  }
  h6 {
    &:first-child {
      margin-top: 0;
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  a.button {
    text-decoration: none;
  }
  table {
    font-size: 14px;
    line-height: 1.2857142857;
  }
  th {
    font-weight: 600;
    padding: 8px;
  }
  td {
    padding: 8px;
  }
  .edit-link {
    clear: both;
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.3333333333;
    margin-top: 20px;
    a {
      color: $color_27;
      text-decoration: none;
      &:hover {
        color: $color_1;
      }
    }
  }
  .more-link {
    display: block;
    padding-top: 15px;
  }
}
.share-buttons {
  margin: 25px 0 0;
  margin: 25px 0 0;
  a {
    display: inline-block;
    margin: 0 3px 0 0;
    padding: 0;
    width: 28px;
    height: 28px;
    color: $color_16;
    background-color: $background-color_2;
    border: 0;
    text-align: center;
    &:hover {
      color: $color_1;
    }
    &:last-child {
      margin-right: 0;
    }
    i {
      font-size: 16px;
      line-height: 26px;
    }
  }
}
.tags-block {
  margin: 25px 0 0;
}
.tags {
  list-style: none;
  margin: 0;
  padding: 0;
  > li {
    display: inline-block;
  }
  a {
    display: inline-block;
    font-size: 12px;
    line-height: 1.5em;
    color: $color_7;
    padding: 2px 7px;
    background-color: $background-color_11;
    border-radius: 3px;
    border: 1px solid transparent;
    margin: 3px 2px 2px;
    &:first-child {
      margin-left: 0;
    }
  }
}
.mask-gallery-grid {
  position: absolute;
  background: #4f565e;
  background: rgba(79, 86, 94, 0.6);
  display: inline-block;
  font-family: $font-family_2;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: 3px solid transparent;
  width: 100%;
  left: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  &:after {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
    width: 40px;
    line-height: 40px;
    font-size: 40px;
    text-align: center;
    content: "\f00e";
    color: $color_7;
  }
}
.single-page-content {
  .content-area {
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: auto;
    min-height: 100%;
  }
}
.single-post {
  .site-content {
    .post-content {
      position: relative;
      padding-left: 50px;
      padding-right: 50px;
      background-color: $background-color_8;
      z-index: 1;
    }
    .post-navigation {
      margin-left: 50px;
      margin-right: 50px;
    }
    .comments-area {
      margin-left: 50px;
      margin-right: 50px;
    }
  }
}
.fw-pricing {
  .fw-package {
    background-color: $background-color_4;
    border: 2px solid #444;
    border-radius: 15px;
    padding: 30px;
    margin-bottom: 10px;
    text-align: center;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    &:hover {
      -webkit-transform: translateY(-9px);
      -ms-transform: translateY(-9px);
      -o-transform: translateY(-9px);
      transform: translateY(-9px);
      -webkit-box-shadow: 0 0 24px rgba(255, 255, 255, 0.14);
      -moz-box-shadow: 0 0 24px rgba(255, 255, 255, 0.14);
      -o-box-shadow: 0 0 24px rgba(255, 255, 255, 0.14);
      box-shadow: 0 0 24px rgba(255, 255, 255, 0.14);
    }
  }
  .fw-package-wrap.highlight-col {
    .fw-package {
      -webkit-transform: translateY(-5px);
      -ms-transform: translateY(-5px);
      -o-transform: translateY(-5px);
      transform: translateY(-5px);
      border-color: $border-color_5;
      -webkit-box-shadow: 0 0 12px rgba(255, 255, 255, 0.12);
      -moz-box-shadow: 0 0 12px rgba(255, 255, 255, 0.12);
      -o-box-shadow: 0 0 12px rgba(255, 255, 255, 0.12);
      box-shadow: 0 0 12px rgba(255, 255, 255, 0.12);
      &:hover {
        -webkit-transform: translateY(-9px);
        -ms-transform: translateY(-9px);
        -o-transform: translateY(-9px);
        transform: translateY(-9px);
        -webkit-box-shadow: 0 0 24px rgba(255, 255, 255, 0.14);
        -moz-box-shadow: 0 0 24px rgba(255, 255, 255, 0.14);
        -o-box-shadow: 0 0 24px rgba(255, 255, 255, 0.14);
        box-shadow: 0 0 24px rgba(255, 255, 255, 0.14);
      }
    }
    .fw-heading-row {
      span {
        position: relative;
        display: inline-block;
        &:after {
          content: "";
          height: 2px;
          margin-top: 0;
          background-color: $background-color_1;
          position: absolute;
          left: 0;
          bottom: -3px;
          right: 0;
        }
      }
    }
  }
  .fw-pricing-row {
    padding: 30px 0 10px;
    span {
      display: block;
      line-height: 1;
      color: $color_3;
      font-size: 42px;
    }
    small {
      color: $color_12;
      display: block;
    }
  }
  .fw-default-row {
    padding: 5px 0;
    font-size: 14px;
  }
  .fw-button-row {
    padding: 10px 0 20px;
  }
  .fw-price-icon-no {
    &:before {
      content: "\f068";
      color: $color_22;
    }
  }
  .fw-price-icon-yes {
    &:before {
      content: "\f058";
      color: $color_23;
    }
  }
}
.fw-table {
  table {
    width: 100%;
    border: 1px solid #eee;
    font-size: 0.875em;
    tr {
      border-bottom: 1px solid #eee;
      &:not(.heading-row) {
        &:last-child {
          border-bottom: 0;
        }
      }
    }
    tr.heading-row {
      border-bottom: 1px solid #eee;
    }
    th {
      padding: 7px 12px;
      border-right: 1px solid #eee;
    }
    td {
      padding: 7px 12px;
      border-right: 1px solid #eee;
      &:last-child {
        border-right: 0;
      }
    }
  }
}
.blog-post-page {
  height: auto;
  .page-wrapper {
    position: relative;
    border-left: 15px solid #f5f5f5;
  }
}
.blog-post-content {
  position: relative;
  background-color: $background-color_8;
  padding: 30px;
  margin: -50px 40px 0;
  .block-title {
    margin: 40px 0 10px;
  }
  .post-comment-add {
    max-width: 700px;
  }
  .post-comments {
    .media {
      margin-top: 30px;
    }
    > .media {
      margin-top: 0;
    }
  }
  .divider {
    color: $color_8;
    margin: 0 2px;
    min-width: 5px;
  }
}
blockquote {
  p {
    &:last-child {
      margin-bottom: 0;
    }
    font-style: italic;
  }
  footer {
    color: $color_10;
    margin-top: 10px;
  }
  small {
    color: $color_10;
    margin-top: 10px;
  }
  .small {
    color: $color_10;
    margin-top: 10px;
  }
  padding: 15px 20px;
  margin: 30px auto;
  width: 90%;
  font-size: 1em;
  border-left: 2px solid #666;
  font-style: italic;
}
blockquote.fw-quote-lg {
  font-size: 125%;
  p {
    font-size: 125%;
  }
}
blockquote.fw-quote-sm {
  font-size: 95%;
  p {
    font-size: 95%;
  }
}
blockquote.fw-quote-center {
  text-align: center;
}
blockquote.fw-quote-right {
  text-align: right;
}
.entry-meta {
  color: $color_10;
  margin: 20px 0 30px;
  clear: both;
  line-height: 1.3333333333;
  a {
    color: $color_10;
    &:hover {
      color: $color_1;
    }
  }
  &:not(.entry-meta-bottom) {
    a {
      &:hover {
        color: $color_24;
      }
    }
  }
}
.post-tags {
  a {
    &:hover {
      color: $color_25;
    }
  }
  margin: 30px 0;
  text-align: left;
}
.entry-meta.entry-meta-bottom {
  .date-author {
    display: inline-block;
    line-height: 28px;
    margin: 5px 0;
    > span {
      &:after {
        content: "";
        height: 14px;
        line-height: 1em;
        display: inline-block;
        margin: 0 6px 0 9px;
        background-color: $background-color_17;
        width: 1px;
        top: 2px;
        position: relative;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &:first-child {
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
  .share-buttons {
    display: inline-block;
    margin: 5px 0;
    a {
      &:hover {
        color: $color_1;
      }
    }
  }
  .post-info {
    span {
      display: inline-block;
      padding: 5px 0;
      line-height: 1em;
    }
  }
}
.post-comments {
  .media-object {
    max-width: 60px;
    border-radius: 50px;
  }
  .media-footer {
    display: inline-block;
    color: $color_10;
    font-size: 12px;
    line-height: 1em;
    a {
      display: inline-block;
      color: $color_10;
      font-size: 12px;
      line-height: 1em;
      &:hover {
        color: $color_1;
      }
    }
  }
  .light-gray {
    color: $color_10;
    font-size: 12px;
  }
}
.site-content.single-post {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  .post-thumbnail {
    margin-top: 0;
  }
  .post-content {
    position: relative;
    z-index: 1;
  }
  .post-navigation {
    position: relative;
    z-index: 1;
  }
  .comments-area {
    position: relative;
    z-index: 1;
  }
}
.post-content {
  .embed-video {
    margin: 40px 0;
  }
  .single-image {
    margin: 40px 0;
  }
}
.wp-block-quote.is-large {
  padding: 15px 20px;
  margin: 20px 0 20px 20px;
  cite {
    font-size: 0.76em;
  }
  footer {
    font-size: 0.76em;
  }
}
.wp-block-quote.is-style-large {
  padding: 15px 20px;
  margin: 20px 0 20px 20px;
  cite {
    font-size: 0.76em;
    font-size: 0.76em;
  }
  footer {
    font-size: 0.76em;
  }
}
.post-thumbnail {
  background: #b2b2b2;
  display: block;
  position: relative;
  width: calc(100% + 140px);
  margin: 40px -70px;
  z-index: 0;
}
a.post-thumbnail {
  &:hover {
    background-color: $background-color_18;
  }
}
.full-width {
  .post-thumbnail {
    img {
      display: block;
      margin: 0 auto;
    }
  }
}
.comment-reply-link {
  &:before {
    font-family: $font-family_2;
    margin-right: 3px;
    content: "\f112";
    margin-right: 2px;
  }
}
.comment-reply-login {
  &:before {
    font-family: $font-family_2;
    margin-right: 3px;
    content: "\f112";
    margin-right: 2px;
  }
}
.comment-edit-link {
  &:before {
    font-family: $font-family_2;
    margin-right: 3px;
    content: "\f040";
  }
  margin-left: 10px;
}
.bypostauthor {
  > article {
    .fn {
      &:before {
        content: "\f005";
        margin: 0 4px 0 -2px;
        position: relative;
        top: 0;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-family: $font-family_2;
        font-size: 16px;
        text-decoration: inherit;
        vertical-align: baseline;
        opacity: 0.5;
      }
    }
  }
}
.comments-area {
  margin: 40px auto 0;
  padding: 0;
}
.comment-list {
  list-style: none;
  margin: 0 0 50px;
  padding: 0;
  font-size: 1em;
  .comment {
    font-size: 1em;
  }
  .reply {
    padding-left: 75px;
    font-size: 12px;
    color: $color_10;
    line-height: 1.2;
    margin-top: 15px;
    a {
      color: $color_10;
    }
  }
  .trackback {
    a {
      color: $color_12;
      &:hover {
        color: $color_1;
      }
    }
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
    padding-top: 24px;
  }
  .pingback {
    a {
      color: $color_12;
      &:hover {
        color: $color_1;
      }
    }
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
    padding-top: 24px;
  }
  article {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
    padding-top: 24px;
  }
  > li {
    &:first-child {
      > article {
        border-top: 0;
      }
    }
  }
  > .pingback {
    &:first-child {
      border-top: 0;
    }
  }
  > .trackback {
    &:first-child {
      border-top: 0;
    }
  }
  .children {
    list-style: none;
    margin-left: 15px;
    padding-left: 10px;
  }
}
.comment-awaiting-moderation {
  padding-left: 75px;
}
.comment-content {
  padding-left: 75px;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
  margin-top: 5px;
  font-size: 1em;
  ul {
    margin: 0 0 24px 22px;
  }
  ol {
    margin: 0 0 24px 22px;
  }
  li {
    > ul {
      margin-bottom: 0;
    }
    > ol {
      margin-bottom: 0;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  a.button {
    text-decoration: none;
  }
  table {
    font-size: 14px;
    line-height: 1.2857142857;
  }
  th {
    font-weight: 600;
    padding: 8px;
  }
  td {
    padding: 8px;
  }
}
.comment-author {
  display: inline-block;
  font-size: 14px;
  line-height: 1.7142857142;
  position: relative;
  .fn {
    color: $color_10;
    font-size: 15px;
    font-weight: 400;
  }
  a {
    &:hover {
      color: $color_1;
    }
  }
  .avatar {
    height: 60px;
    position: absolute;
    top: 0;
    left: -75px;
    width: 60px;
    min-width: 60px;
    border-radius: 50px;
  }
}
.comment-metadata {
  display: inline-block;
  padding-left: 10px;
  margin-left: 5px;
  border-left: 1px solid #d3d3d3;
  font-size: 12px;
  color: $color_10;
  line-height: 1.2;
  a {
    color: $color_12;
    &:hover {
      color: $color_1;
    }
  }
}
.comment-meta {
  padding-left: 75px;
}
.says {
  display: none;
}
.comment-respond {
  margin-bottom: 24px;
  padding: 0;
  h3 {
    margin-top: 0;
    margin-bottom: 24px;
  }
}
.comment {
  .comment-respond {
    margin-top: 24px;
  }
}
.no-comments {
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  margin-top: 24px;
}
.comment-form {
  label {
    display: inline-block;
    margin-left: 3px;
  }
  input[type="text"] {
    width: 100%;
  }
  input[type="email"] {
    width: 100%;
  }
  input[type="url"] {
    width: 100%;
  }
}
.form-allowed-tags {
  font-size: 12px;
  line-height: 1.5;
  code {
    font-size: 12px;
    line-height: 1.5;
  }
}
.required {
  color: $color_26;
}
.comment-reply-title {
  small {
    a {
      color: $color_16;
      &:hover {
        color: $color_1;
      }
    }
  }
}
.comment-navigation {
  font-size: 12px;
  line-height: 2;
  margin-bottom: 48px;
  .nav-next {
    display: inline-block;
  }
  .nav-previous {
    display: inline-block;
    a {
      margin-right: 10px;
    }
  }
}
#comment-nav-above {
  margin-top: 36px;
  margin-bottom: 0;
}
.entry-header {
  position: relative;
  z-index: 1;
}
.entry-title {
  font-size: 32px;
  line-height: 1.3;
  margin: 15px 0 20px;
  a {
    color: $color_11;
    &:hover {
      color: $color_1;
    }
  }
}
.entry-meta + .cat-links {
  &:empty {
    display: none;
  }
}
.entry-meta.entry-meta-top {
  > span {
    &:after {
      content: "";
      height: 14px;
      line-height: 1em;
      display: inline-block;
      margin: 0 6px 0 9px;
      background-color: $background-color_17;
      width: 1px;
      top: 2px;
      position: relative;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    &:first-child {
      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
}
.post-categories {
  display: inline-block;
  margin: 0 0 7px;
  padding: 0;
  list-style: none;
  li {
    display: inline-block;
  }
}
.cat-links {
  display: block;
  padding: 5px 0;
  li {
    a {
      display: inline-block;
      font-size: 14px;
      margin: 0 6px 6px 0;
      color: $color_7;
      background-color: $background-color_1;
      padding: 2px 5px;
      &:hover {
        color: $color_7;
        background-color: $background-color_1;
      }
    }
    &:first-child {
      a {
        margin-left: 0;
      }
    }
  }
}
.byline {
  display: none;
}
.single {
  .byline {
    display: inline;
  }
}
.group-blog {
  .byline {
    display: inline;
  }
}
.entry-meta-bottom {
  .tags {
    float: left;
    margin: 5px 0;
  }
  .entry-share {
    float: right;
    margin: 0;
    a {
      &:hover {
        color: $color_7;
      }
    }
    h4 {
      display: inline-block;
      float: left;
      margin: 2px 10px 0;
    }
  }
}
.entry-summary {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
  h1 {
    &:first-child {
      margin-top: 0;
    }
  }
  h2 {
    &:first-child {
      margin-top: 0;
    }
  }
  h3 {
    &:first-child {
      margin-top: 0;
    }
  }
  h4 {
    &:first-child {
      margin-top: 0;
    }
  }
  h5 {
    &:first-child {
      margin-top: 0;
    }
  }
  h6 {
    &:first-child {
      margin-top: 0;
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  a.button {
    text-decoration: none;
  }
}
.page-content {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
  h1 {
    &:first-child {
      margin-top: 0;
    }
  }
  h2 {
    &:first-child {
      margin-top: 0;
    }
  }
  h3 {
    &:first-child {
      margin-top: 0;
    }
  }
  h4 {
    &:first-child {
      margin-top: 0;
    }
  }
  h5 {
    &:first-child {
      margin-top: 0;
    }
  }
  h6 {
    &:first-child {
      margin-top: 0;
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  a.button {
    text-decoration: none;
  }
}
.wrapper {
  .entry-content {
    padding-top: 0;
    padding-bottom: 30px;
  }
}
.hentry {
  .mejs-container {
    margin: 12px 0 18px;
    .mejs-controls {
      background: #000;
      .mejs-time {
        padding-top: 9px;
      }
    }
  }
  .mejs-mediaelement {
    background: #000;
  }
  .mejs-controls {
    .mejs-time-rail {
      .mejs-time-loaded {
        background: #fff;
      }
      .mejs-time-current {
        background: #24890d;
      }
      .mejs-time-total {
        background: rgba(255, 255, 255, 0.33);
      }
      span {
        border-radius: 0;
      }
    }
    .mejs-horizontal-volume-slider {
      .mejs-horizontal-volume-current {
        background: #fff;
        border-radius: 0;
      }
      .mejs-horizontal-volume-total {
        background: rgba(255, 255, 255, 0.33);
        border-radius: 0;
      }
    }
  }
  .mejs-overlay-loading {
    background: 0 0;
  }
}
.page-links {
  clear: both;
  font-size: 12px;
  font-weight: 300;
  line-height: 2;
  margin: 24px 0;
  a {
    background: #fff;
    border: 1px solid #fff;
    display: inline-block;
    margin: 0 1px 2px 0;
    text-align: center;
    width: 22px;
    border-radius: 5px;
    &:hover {
      background: #007ced;
      border: 1px solid #007ced;
      color: $color_7;
    }
  }
  > span {
    background: #fff;
    border: 1px solid #fff;
    display: inline-block;
    margin: 0 1px 2px 0;
    text-align: center;
    width: 22px;
    border-radius: 5px;
  }
  > .page-links-title {
    height: auto;
    margin: 0;
    padding-right: 7px;
    width: auto;
  }
}
.format-aside {
  .entry-content {
    padding-top: 0;
  }
  .entry-summary {
    padding-top: 0;
  }
}
.format-quote {
  .entry-content {
    padding-top: 0;
  }
  .entry-summary {
    padding-top: 0;
  }
}
.format-link {
  .entry-content {
    padding-top: 0;
  }
  .entry-summary {
    padding-top: 0;
  }
}
.nav-links {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
}
.post-navigation {
  margin: 40px auto 0;
  padding: 0;
  a {
    display: inline-block;
    width: auto;
    max-width: 46%;
    padding: 9px 0;
    color: $color_28;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.7142857142;
    &:hover {
      color: $color_1;
    }
  }
  a[rel="next"] {
    float: right;
    text-align: right;
  }
  .meta-nav {
    color: $color_12;
    display: block;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}
.image-navigation {
  margin: 40px auto 0;
  padding: 0;
  .previous-image {
    display: inline-block;
    width: auto;
    max-width: 46%;
    padding: 9px 0;
  }
  .next-image {
    display: inline-block;
    width: auto;
    max-width: 46%;
    padding: 9px 0;
  }
  .previous-image[rel="next"] {
    float: right;
    text-align: right;
  }
  .next-image[rel="next"] {
    float: right;
    text-align: right;
  }
  a {
    color: $color_28;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.7142857142;
    &:hover {
      color: $color_1;
    }
  }
}
.paging-navigation {
  margin: 50px 0 0;
  .loop-pagination {
    margin: 0;
    text-align: center;
  }
  .page-numbers {
    display: inline-block;
    font-size: 14px;
    line-height: 1em;
    margin-right: 5px;
    padding: 7px 12px;
    background-color: $background-color_8;
    border-radius: 8px;
    border: 2px solid #e5e5e5;
    color: $color_25;
  }
  a {
    color: $color_25;
    &:hover {
      background-color: $background-color_1;
      color: $color_7;
    }
    &:focus {
      background-color: $background-color_1;
      color: $color_7;
    }
  }
  .page-numbers.current {
    color: $color_25;
    border: 2px solid #007ced;
    &:hover {
      color: $color_25;
      border: 2px solid #007ced;
    }
    &:focus {
      color: $color_25;
      border: 2px solid #007ced;
    }
  }
}
.attachment {
  .content-sidebar {
    display: none;
  }
  .post-thumbnail {
    display: none;
  }
  .entry-content {
    padding-top: 0;
  }
  footer.entry-meta {
    text-transform: none;
  }
}
.entry-attachment {
  .attachment {
    margin-bottom: 24px;
  }
}
.blog {
  .page-title {
    color: $color_12;
    font-size: 24px;
    line-height: 1.5;
    margin: 13px 0 0;
  }
}
.taxonomy-description {
  color: $color_27;
  font-size: 14px;
  line-height: 1.2857142857;
  padding-top: 18px;
  p {
    margin-bottom: 18px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
}
.author-description {
  color: $color_27;
  font-size: 14px;
  line-height: 1.2857142857;
  padding-top: 18px;
  p {
    margin-bottom: 18px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
}
.gallery {
  display: inline-block;
  margin-bottom: 20px;
}
.gallery-item {
  float: left;
  margin: 0 4px 4px 0;
  overflow: hidden;
  position: relative;
  &:hover {
    .gallery-caption {
      opacity: 1;
    }
  }
}
.gallery-columns-1 {
  .gallery-item {
    max-width: 100%;
    clear: left;
    &:nth-of-type(1n) {
      margin-right: 0;
    }
  }
}
.gallery-columns-2 {
  .gallery-item {
    max-width: 48%;
    max-width: -webkit-calc(50% - 4px);
    max-width: calc(50% - 4px);
    &:nth-of-type(2n) {
      margin-right: 0;
    }
    &:nth-of-type(2n + 1) {
      clear: left;
    }
  }
}
.gallery-columns-3 {
  .gallery-item {
    max-width: 32%;
    max-width: -webkit-calc(33.3% - 4px);
    max-width: calc(33.3% - 4px);
    &:nth-of-type(3n) {
      margin-right: 0;
    }
    &:nth-of-type(3n + 1) {
      clear: left;
    }
  }
}
.gallery-columns-4 {
  .gallery-item {
    max-width: 23%;
    max-width: -webkit-calc(25% - 4px);
    max-width: calc(25% - 4px);
    &:nth-of-type(4n) {
      margin-right: 0;
    }
    &:nth-of-type(4n + 1) {
      clear: left;
    }
  }
}
.gallery-columns-5 {
  .gallery-item {
    max-width: 19%;
    max-width: -webkit-calc(20% - 4px);
    max-width: calc(20% - 4px);
    &:nth-of-type(5n) {
      margin-right: 0;
    }
    &:nth-of-type(5n + 1) {
      clear: left;
    }
  }
}
.gallery-columns-6 {
  .gallery-item {
    max-width: 15%;
    max-width: -webkit-calc(16.7% - 4px);
    max-width: calc(16.7% - 4px);
    &:nth-of-type(6n) {
      margin-right: 0;
    }
    &:nth-of-type(6n + 1) {
      clear: left;
    }
  }
}
.gallery-columns-7 {
  .gallery-item {
    max-width: 13%;
    max-width: -webkit-calc(14.28% - 4px);
    max-width: calc(14.28% - 4px);
    &:nth-of-type(7n) {
      margin-right: 0;
    }
    &:nth-of-type(7n + 1) {
      clear: left;
    }
  }
  .gallery-caption {
    display: none;
  }
}
.gallery-columns-8 {
  .gallery-item {
    max-width: 11%;
    max-width: -webkit-calc(12.5% - 4px);
    max-width: calc(12.5% - 4px);
    &:nth-of-type(8n) {
      margin-right: 0;
    }
    &:nth-of-type(8n + 1) {
      clear: left;
    }
  }
  .gallery-caption {
    display: none;
  }
}
.gallery-columns-9 {
  .gallery-item {
    max-width: 9%;
    max-width: -webkit-calc(11.1% - 4px);
    max-width: calc(11.1% - 4px);
    &:nth-of-type(9n) {
      margin-right: 0;
    }
    &:nth-of-type(9n + 1) {
      clear: left;
    }
  }
  .gallery-caption {
    display: none;
  }
}
.gallery-columns-1.gallery-size-medium {
  figure.gallery-item {
    &:nth-of-type(1n + 1) {
      clear: left;
    }
  }
}
.gallery-columns-1.gallery-size-thumbnail {
  figure.gallery-item {
    &:nth-of-type(1n + 1) {
      clear: left;
    }
  }
}
.gallery-columns-2.gallery-size-thumbnail {
  figure.gallery-item {
    &:nth-of-type(2n + 1) {
      clear: left;
    }
  }
}
.gallery-columns-3.gallery-size-thumbnail {
  figure.gallery-item {
    &:nth-of-type(3n + 1) {
      clear: left;
    }
  }
}
.gallery-caption {
  background-color: $background-color_19;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: $color_7;
  font-size: 12px;
  line-height: 1.5;
  margin: 0;
  max-height: 50%;
  opacity: 0;
  padding: 6px 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  width: 100%;
  &:before {
    content: "";
    height: 100%;
    min-height: 49px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
.contributor {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 48px 10px;
  &:first-of-type {
    padding-top: 24px;
  }
}
.contributor-info {
  margin: 0 auto;
}
.contributor-avatar {
  border: 1px solid rgba(0, 0, 0, 0.1);
  float: left;
  margin: 0 30px 20px 0;
  padding: 2px;
}
.contributor-name {
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  margin: 0;
}
.contributor-bio {
  a {
    &:hover {
      text-decoration: none;
    }
  }
}
.contributor-posts-link {
  display: inline-block;
  line-height: normal;
  padding: 10px 30px;
  &:before {
    content: "\f443";
  }
}
.sidebar-item {
  .menu-item {
    i {
      display: none;
    }
  }
  > select {
    margin: 14px 20px 19px;
  }
}
.sidebar {
  .sidebar-item {
    ul {
      margin: 0;
      list-style: none;
      li {
        list-style: none;
      }
    }
  }
}
.calendar_wrap {
  padding: 12px 22px;
  table {
    width: 100%;
    border: 0;
  }
  th {
    background-color: $background-color_17;
    text-align: center;
    font-size: 11px;
    border: 0;
  }
  td {
    background-color: $background-color_10;
    text-align: center;
    border: 0;
  }
  td#today {
    background-color: $background-color_1;
    color: $color_7;
    padding: 0 3px;
  }
}
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  word-wrap: normal !important;
}
.search-form {
  position: relative;
  overflow: hidden;
  label {
    font-weight: inherit;
    width: 100%;
    margin: 0;
    span {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 0;
      text-indent: -3000px;
      opacity: 0.35;
      margin: 0;
    }
    &:after {
      height: 48px;
      width: 48px;
      position: absolute;
      top: 0;
      right: 0;
      content: "\f002";
      font-size: 1.2em;
      font-family: $font-family_2;
      line-height: 48px;
      text-align: center;
      border-left: none;
      color: $color_29;
    }
  }
  input[type="search"] {
    padding: 10px 50px 10px 16px;
    margin-bottom: 0;
    position: relative;
    border: none;
    width: 100%;
    height: 48px;
    color: $color_11;
    outline: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  input[type="text"] {
    padding: 10px 50px 10px 16px;
    margin-bottom: 0;
    position: relative;
    border: none;
    width: 100%;
    height: 48px;
    color: $color_11;
    outline: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    &:focus + input[type="submit"] {
      margin-right: 0;
    }
  }
  input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0;
    height: 48px;
    width: 48px;
    background: 0 0;
    border: none;
    z-index: 1000;
    cursor: pointer;
    text-indent: -200px;
    overflow: hidden;
    border-radius: 0;
    box-shadow: none;
    &:hover {
      position: absolute;
      top: 0;
      right: 0;
      height: 48px;
      width: 48px;
      background: 0 0;
      border: none;
      z-index: 1000;
      cursor: pointer;
      text-indent: -200px;
      overflow: hidden;
      border-radius: 0;
      box-shadow: none;
    }
    &:focus {
      position: absolute;
      top: 0;
      right: 0;
      height: 48px;
      width: 48px;
      background: 0 0;
      border: none;
      z-index: 1000;
      cursor: pointer;
      text-indent: -200px;
      overflow: hidden;
      border-radius: 0;
      box-shadow: none;
    }
  }
}
.page-content-none {
  padding-top: 30px;
  text-align: center;
  .search-form {
    max-width: 270px;
    margin: 30px auto;
    input[type="search"] {
      border: 1px solid #e5e5e5;
      &:focus {
        border-color: $border-color_6;
      }
    }
  }
}
.site-footer {
  display: block;
  position: relative;
  margin: 60px -70px -60px;
  padding: 15px 45px;
  background-color: $background-color_9;
  border-top: 2px solid #333;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  .footer-social-links {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin-right: 25px;
      &:last-child {
        margin-right: 0;
      }
      a {
        color: $color_29;
        text-decoration: none;
        line-height: 21px;
        font-size: 13px;
        opacity: 0.6;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
.footer-social {
  float: left;
}
.footer-copyrights {
  float: right;
  p {
    color: $color_29;
    line-height: 21px;
    font-size: 13px;
    margin: 0;
  }
}
.nothing-found {
  text-align: center;
  .page-content {
    margin: 10% 0 15%;
  }
  h1 {
    font-size: 160px;
    line-height: 1.2em;
    margin: 0;
    color: $color_17;
    text-align: center;
  }
  p {
    display: inline-block;
    color: $color_7;
    background-color: $background-color_1;
    padding: 0 10px;
    font-size: 16px;
  }
}
.home-content.third-style {
  margin: 0;
  .start-page-full-width {
    width: calc(100% + 140px);
    margin-left: -70px;
    .row {
      margin-right: 0;
      margin-left: 0;
    }
    .inner-content {
      height: 100%;
      min-height: 100%;
      .fill-block {
        position: relative;
        height: 100%;
        min-height: 300px;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .hp-text-block {
      position: relative;
      padding: 70px 15%;
      background-color: $background-color_4;
      height: 100%;
      min-height: 100px;
      .sp-subtitle {
        color: $color_16;
        font-weight: 300;
      }
    }
    div[class^="col-"] {
      padding: 0 !important;
    }
  }
  .hp-main-title {
    font-size: 48px;
    line-height: 1.2em;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .hp-buttons {
    margin-top: 30px;
  }
  .start-page {
    .vcentered {
      vertical-align: middle;
      width: 100%;
      align-items: center;
      display: flex;
      display: -ms-flexbox;
      -ms-flex-pack: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-direction: normal;
      -moz-box-direction: normal;
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
      -webkit-box-align: center;
      -moz-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
      .row {
        width: 100%;
      }
    }
    .title-block {
      position: relative;
      text-align: center;
      h2 {
        color: $color_7;
        font-size: 70px;
        margin: 0;
        text-align: center;
      }
      .sp-subtitle {
        color: $color_7;
        font-size: 21px;
        font-weight: 300;
        font-family: $font-family_5;
        margin: 5px 0;
        text-align: center;
      }
    }
  }
  .start-page-content {
    .social-links {
      position: relative;
      text-align: center;
      margin: 0 auto;
      margin-top: 20px;
      a {
        display: inline-block;
        width: 36px;
        height: 36px;
        border-radius: 24px;
        background-color: $background-color_8;
        margin: 0 1px;
        color: $color_10;
        text-align: center;
        -webkit-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
        &:hover {
          -webkit-box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.2);
        }
        i {
          line-height: 36px;
          font-size: 16px;
        }
      }
    }
    .page-content {
      padding-top: 45px;
      background-color: $background-color_8;
      .download-resume {
        margin-top: 15px;
      }
    }
    .block-title {
      h3 {
        margin-bottom: 10px;
      }
    }
  }
}
.skill-slider-section {
  margin-top: 40px;
}
.slick-slide {
  img {
    display: block;
    max-width: 170px;
    background: #fff;
    padding: 10px 15px;
    border-radius: 15px;
  }
}
.madewithlove {
  position: relative;
  svg {
    fill: red;
    position: absolute;
    top: 3px;
    right: -20px;
    font-size: 16px;
  }
}
#page-wrap {
  text-align: center;
  overflow: auto;
  top: 45%;
  position: relative;
}

.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #f5f5f5;
  transition: color 0.2s;
  opacity: 0.55;
  a {
    color: #f5f5f5;
    &.active {
      opacity: 1;
    }
  }
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 10px;
}

button#react-burger-menu-btn {
  top: 0px !important;
  z-index: unset;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #f5f5f5;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #000;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-menu-wrap {
  left: 0;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 991px) {
  .fw-section-fluid {
    width: calc(100% + 80px);
    margin: 0 -40px;
    padding: 0 25px;
  }
  .mobile-visible {
    display: block;
    visibility: visible;
  }
  .mobile-hidden {
    display: none !important;
    visibility: hidden !important;
  }
  .border-block-top-110 {
    display: none;
  }
  .page-container {
    border-radius: 0;
    margin: 0;
    max-width: 100%;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    padding: 52px 40px 60px;
  }
  .page-title {
    padding: 30px 40px;
    margin-right: -40px;
    margin-left: -40px;
    margin-bottom: 40px;
    h1 {
      margin: 0;
      font-size: 36px;
    }
    .page-subtitle {
      position: relative;
      right: auto;
      h4 {
        margin: 5px 0;
      }
    }
  }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    padding: 0;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  }
  .header-content {
    z-index: 999;
    background-color: $background-color_6;
    padding: 10px 20px;
  }
  .header.animate {
    -webkit-transition: all 0.44s ease-in-out;
    -moz-transition: all 0.44s ease-in-out;
    -o-transition: all 0.44s ease-in-out;
    -ms-transition: all 0.44s ease-in-out;
    transition: all 0.44s ease-in-out;
  }
  .mobile-menu-hide {
    width: 0;
    right: 0;
    margin-right: -100%;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .site-nav {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: $background-color_6;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 320px;
    height: calc(100% - 52px);
    left: auto;
    top: 52px;
    z-index: -1;
    opacity: 1;
    overflow: auto;
    visibility: visible;
    -webkit-box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.08);
    -webkit-transition: all 0s ease-in-out;
    -moz-transition: all 0s ease-in-out;
    -o-transition: all 0s ease-in-out;
    -ms-transition: all 0s ease-in-out;
    transition: all 0s ease-in-out;
  }
  .site-nav.animate {
    -webkit-transition: all 0.44s ease-in-out;
    -moz-transition: all 0.44s ease-in-out;
    -o-transition: all 0.44s ease-in-out;
    -ms-transition: all 0.44s ease-in-out;
    transition: all 0.44s ease-in-out;
  }
  .site-main-menu {
    li {
      display: block;
      margin: 0 30px;
      a {
        display: block;
      }
    }
  }
  .sub-menu {
    position: relative;
    display: block;
    visibility: visible;
    opacity: 1;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin: 0 0 0 20px;
    }
  }
  .logo-symbol {
    width: 35px;
    height: 35px;
    font-size: 18px;
    margin-top: 0;
    padding: 7px;
  }
  .logo-text {
    display: inline-block;
    line-height: 32px;
    font-size: 18px;
  }
  .menu-toggle {
    display: inline-block;
    float: right;
    line-height: 32px;
    font-size: 21px;
    color: $color_9;
  }
  .mobile-header {
    position: fixed;
    display: inline-block;
    width: 100%;
    max-width: 100%;
    background-color: $background-color_8;
    border-bottom: 1px solid #f5f5f5;
    left: 0;
    right: 0;
    height: 50px;
    z-index: 1002;
  }
  .mobile-logo-container {
    margin: 0 20px;
    text-align: left;
  }
  .mobile-header-image {
    display: inline-block;
    float: left;
    img {
      max-height: 34px;
      margin: 8px 8px 8px 0;
    }
  }
  .mobile-site-title {
    display: inline-block;
    float: left;
    color: $color_25;
    font-size: 18px;
    font-weight: 600;
    font-family: $font-family_3;
    margin: 0;
    line-height: 50px;
    a {
      color: $color_25;
      &:hover {
        color: $color_25;
      }
      &:focus {
        color: $color_25;
      }
    }
  }
  .single-page-content {
    padding-left: 0;
    margin-left: 0;
    .content-area {
      .page-content {
        padding: 0;
      }
      display: block;
    }
  }
  .page-content {
    padding: 0;
  }
  .single-post {
    .single-page-content {
      .content-area {
        padding: 0;
      }
    }
    .site-content {
      .post-content {
        padding-left: 30px;
        padding-right: 30px;
      }
      .post-navigation {
        margin-left: 30px;
        margin-right: 30px;
      }
      .comments-area {
        margin-left: 30px;
        margin-right: 30px;
      }
    }
  }
  .list-view {
    .single-page-content {
      .content-area {
        &:not(.projects-grid) {
          padding: 0;
        }
      }
    }
  }
  .single-fw-portfolio {
    .single-page-content {
      .content-area {
        padding: 0;
      }
    }
  }
  .ajax-page-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
  .lm-pricing {
    .lm-package {
      max-width: 400px;
      margin: 0 auto 25px;
    }
    .lm-package-wrap {
      &:last-child {
        .lm-package {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .blog-masonry.three-columns {
    .item {
      width: 50%;
    }
  }
  .site-content.single-post {
    .post-thumbnail {
      margin-top: 0;
    }
  }
  .content-page-with-sidebar {
    .page-content {
      width: 100%;
      display: block;
    }
  }
  .blog-sidebar {
    top: 50px;
    height: calc(100% - 50px);
    min-height: calc(100% - 50px);
    width: 275px;
    right: -275px;
  }
  .post-thumbnail {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .alignfull {
    margin-left: -70px;
    margin-right: -70px;
    min-width: calc(100% + 140px);
  }
  .alignwide {
    margin-left: -12px;
    margin-right: -12px;
    min-width: calc(100% + 24px);
  }
  .sidebar-toggle {
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    line-height: 38px;
    text-align: center;
    background-color: $background-color_10;
    left: -40px;
    font-size: 19px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    span {
      left: 14px;
      &:nth-child(1) {
        top: 10px;
      }
      &:nth-child(2) {
        top: 16px;
      }
      &:nth-child(3) {
        top: 22px;
      }
    }
  }
  .sidebar-toggle.open {
    span {
      &:nth-child(1) {
        top: 12px;
      }
      &:nth-child(3) {
        top: 21px;
      }
    }
  }
  .project-description {
    margin-left: 0;
  }
  .post-content {
    .single-image {
      margin: 30px 0;
    }
  }
}
@media only screen and (min-width: 991px) {
  .header {
    width: calc(100% + 40px);
    margin-left: -20px;
    &::-webkit-scrollbar {
      position: absolute;
      width: 5px;
      margin-left: -5px;
    }
    &::-webkit-scrollbar-track {
      background-color: $background-color_8;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $background-color_5;
    }
  }
  .list-view {
    .site-content {
      article {
        border-bottom: 0;
        &:after {
          content: "";
          height: 1px;
          position: absolute;
          display: block;
          bottom: 0;
          width: calc(100% - 100px);
          z-index: 1;
          background-color: $background-color_17;
          margin: 0 auto;
          left: 0;
          right: 0;
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
  body {
    &::-webkit-scrollbar {
      position: absolute;
      width: 5px;
      margin-left: -5px;
    }
    &::-webkit-scrollbar-track {
      background-color: $background-color_8;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $background-color_5;
    }
  }
  .single-page-content {
    &::-webkit-scrollbar {
      position: absolute;
      width: 5px;
      margin-left: -5px;
    }
    &::-webkit-scrollbar-track {
      background-color: $background-color_8;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $background-color_5;
    }
  }
  .ajax-page-content {
    &::-webkit-scrollbar {
      position: absolute;
      width: 5px;
      margin-left: -5px;
    }
    &::-webkit-scrollbar-track {
      background-color: $background-color_8;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $background-color_5;
    }
  }
  .blog-sidebar {
    .blog-sidebar-content {
      &::-webkit-scrollbar {
        position: absolute;
        width: 5px;
        margin-left: -5px;
      }
      &::-webkit-scrollbar-track {
        background-color: $background-color_8;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $background-color_5;
      }
    }
  }
  .page-container {
    max-width: 100%;
    border-radius: 0;
    margin: 0;
    min-height: 100vh;
    padding-bottom: 120px;
  }
  .page-content.single-post {
    max-width: 1320px;
    margin: 0 auto;
  }
  .post-content {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
  }
  .post-thumbnail {
    width: calc(100% + 140px);
    margin: 40px -70px;
    z-index: 0;
  }
  .site-footer {
    position: absolute;
    margin: 60px 0 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
}
@media only screen and (min-width: 992px) {
  .site-main-menu {
    li {
      &:hover {
        > .sub-menu {
          visibility: visible;
          opacity: 1;
          top: 100%;
        }
      }
      &:last-child {
        > .sub-menu {
          left: auto;
          right: 0;
        }
      }
    }
    .sub-menu {
      display: block;
      position: absolute;
      background-color: $background-color_4;
      left: -23px;
      top: 100px;
      width: auto;
      max-width: none;
      margin: 0;
      padding: 20px 23px;
      border-radius: 8px;
      -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      opacity: 0;
      visibility: hidden;
      -moz-transition: all 0.25s ease-in-out;
      -webkit-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
    }
    .menu-item-has-children {
      > a {
        &:after {
          content: "\f107";
          display: inline-block;
          font-family: $font-family_2;
          font-style: normal;
          font-weight: 700;
          text-rendering: auto;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          margin-left: 5px;
          opacity: 0.3;
          background-color: $background-color_2;
        }
      }
    }
  }
  .sub-menu {
    li {
      display: block;
      margin: 0 0 12px;
      padding: 0;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        display: block;
        opacity: 0.5;
        line-height: 1.6em;
        width: max-content;
        &:hover {
          opacity: 1;
        }
      }
    }
    li.current-menu-item {
      a {
        opacity: 1;
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .home-content {
    margin: 5% 0;
  }
}
@media only screen and (max-width: 767px) {
  .home-photo {
    width: 60%;
    padding-bottom: calc(60% - 36px);
    margin: 30px auto;
  }
  .home-text {
    padding-right: 0;
    h1 {
      text-align: center;
    }
    h4 {
      text-align: center;
    }
  }
  .home-text.hp-left {
    padding-left: 0;
    text-align: center;
  }
  .home-buttons {
    text-align: center;
  }
  ul.social-links {
    text-align: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 648px) {
  .home-photo {
    width: 70%;
    padding-bottom: calc(70% - 36px);
  }
  .home-photo.hp-left {
    width: 70%;
    padding-bottom: calc(70% - 36px);
  }
}
@media only screen and (max-width: 500px) {
  .home-photo {
    width: 90%;
    padding-bottom: calc(90% - 36px);
  }
  .home-photo.hp-left {
    width: 90%;
    padding-bottom: calc(90% - 36px);
  }
  .home-text {
    h1 {
      font-size: 36px;
      line-height: 42px;
    }
    h4 {
      font-size: 14px;
    }
  }
}
@media all and (-ms-high-contrast: none) {
  .start-page {
    .section-inner.vcentered {
      .mask {
        height: 100vh;
      }
    }
  }
}
@media (-ms-high-contrast: active) {
  .start-page {
    .section-inner.vcentered {
      .mask {
        height: 100vh;
      }
    }
  }
}
@media only screen and (max-width: 1320px) {
  .portfolio-grid {
    figure {
      width: calc(33.33333% - 16px);
    }
  }
  .page-container {
    max-width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .portfolio-grid {
    figure {
      width: calc(50% - 16px);
      width: calc(50% - 16px);
    }
  }
  .info-list {
    margin-top: 20px;
  }
  .blog-post-content {
    padding: 0;
    margin: 30px 0 0;
  }
  .blog-masonry.two-columns {
    .item {
      width: 100%;
    }
  }
  .blog-masonry.three-columns {
    .item {
      width: 100%;
    }
  }
  .portfolio-grid.three-columns {
    figure {
      width: calc(50% - 16px);
    }
  }
  .portfolio-grid.four-columns {
    figure {
      width: calc(33.3333333% - 16px);
    }
  }
  .portfolio-grid.five-columns {
    figure {
      width: calc(33.3333333% - 16px);
    }
  }
  .page-portfolio-loaded {
    .ajax-page-wrapper {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  .portfolio-page-carousel {
    .owl-nav {
      .owl-prev {
        margin-left: 0;
        background-color: $background-color_20;
      }
      .owl-next {
        margin-right: 0;
        background-color: $background-color_20;
      }
    }
  }
  .fw-container {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .nothing-found {
    h1 {
      font-size: 88px;
    }
  }
  .entry-meta.entry-meta-bottom {
    .share-buttons {
      display: block;
      float: none;
    }
    .date-author + .share-buttons {
      margin-top: 10px;
    }
  }
  .entry-meta-bottom {
    .tags {
      display: block;
      float: none;
    }
  }
  .site-content {
    .entry-header {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .list-view {
    .post-content {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
    .post-navigation {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
    .comments-area {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
    .paging-navigation {
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .single-post {
    .site-content {
      .post-content {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        position: relative;
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;
        z-index: 1;
      }
      .post-navigation {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
      }
      .comments-area {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .fw-table {
    width: 100%;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .comment-metadata {
    padding: 0;
    border: 0;
    margin: 0 0 5px;
    display: block;
  }
  .alignfull {
    margin-left: -40px;
    margin-right: -40px;
    min-width: calc(100% + 80px);
  }
  .footer-social {
    float: none;
    display: block;
    text-align: center;
    margin: 12px 0;
  }
  .footer-copyrights {
    float: none;
    display: block;
    text-align: center;
    margin: 12px 0;
  }
  .madewithlove {
    svg {
      right: unset;
      left: unset;
      margin-left: 5px;
    }
  }
}
@media only screen and (max-width: 480px) {
  .portfolio-grid {
    figure {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
    margin-left: 0;
    margin-right: 0;
  }
  .portfolio-grid.two-columns {
    figure {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .portfolio-grid.three-columns {
    figure {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .portfolio-grid.four-columns {
    figure {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .portfolio-grid.five-columns {
    figure {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .nothing-found {
    h2 {
      font-size: 60px;
    }
  }
  .entry-title {
    font-size: 27px;
  }
  .controls.two-columns {
    .left-column {
      width: 100%;
      float: none;
      margin-right: 0;
    }
    .right-column {
      width: 100%;
      float: none;
    }
  }
  .comment-list {
    .children {
      margin-left: 7px;
      padding-left: 5px;
    }
  }
}
@media screen and (min-width: 768px) {
  .home-content.third-style {
    .inner-text-block {
      .hp-text-block {
        -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.11);
        -moz-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.11);
        box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.11);
      }
      &:not(.text-reverse) {
        position: relative;
        padding: 50px 70px 50px 0;
        margin-left: -70px;
        z-index: 1;
      }
    }
    .inner-text-block.text-reverse {
      position: relative;
      padding: 50px 0 50px 70px;
      margin-right: -70px;
      z-index: 1;
    }
  }
}
